<script>
  import { onMount } from "svelte";
  import { fly } from "svelte/transition";
  import { _ } from "svelte-i18n";

  import { initialCalculation, calculatorParams, app } from "../store";
  import FinanceAmountArch from "../assets/finance_amount_arch.svelte";
  import LogoBig from "../assets/logo_big.svelte";
  import CheckMarkBig from "../assets/check_mark_big.svelte";
  import HyperpayRadioGroup from "../components/hyperpay_radio_group.svelte";
  import Spinner from "../components/spinner.svelte";
  import Refresh from "../assets/refresh.svelte";

  import { tweened } from "svelte/motion";
  import { cubicOut } from "svelte/easing";

  import illustration from "../../assets/images/illustration.png";
  import { post } from "axios";
  import { prettyNumbers, convertToEnglishNumbers } from "../../lib/utils";

  import { formatValueWithState } from "../../lib/svelte_actions";

  const financeAmount = tweened(0, {
    duration: 1000,
    easing: cubicOut,
  });

  const installment = tweened(0, {
    duration: 1000,
    easing: cubicOut,
  });

  const amountWithBankProfit = tweened(0, {
    duration: 1000,
    easing: cubicOut,
  });

  const tenure = tweened(0, {
    duration: 1000,
    easing: cubicOut,
  });

  onMount(() => {
    financeAmount.set($initialCalculation.amount);
    installment.set($initialCalculation.monthly_installment);
    amountWithBankProfit.set($initialCalculation.amount_with_bank_profit);
    tenure.set($initialCalculation.tenure);
  });

  function createCalculation(event) {
    $app.calculationStatus = "pending";

    post("/finance_real_estate_create_calculation", {
      finance_real_estate_calculation: {
        mobile: $app.mobile,
        calculator_params: JSON.stringify($calculatorParams),
      },
    })
      .then(() => {
        $app.calculationStatus = "created";
      })
      .catch(() => {
        $app.calculationStatus = "error";
      });
  }

  $: disableMobileSubmit =
    $app.calculationStatus === "pending" ||
    $app.mobile === null ||
    !$app.mobile.match(/^05[0-9]{8}$/);

  $: showPayment =
    $app.calculationStatus === null || $app.calculationStatus === "pending";
</script>

<div class="pt-14 px-4 pb-4">
  <h1 class="text-3xl font-medium text-center mb-14">
    {$_("summary_page.title")}
  </h1>
  <div class="relative flex flex-col items-center whitespace-nowrap">
    <div class="-mt-5">
      <LogoBig />
    </div>

    <dl class="text-center mt-6 flex flex-col space-y-2">
      <dt class="text-base font-medium">{$_("summary_page.financeAmount")}</dt>
      <dd class="text-3xl font-bold">{prettyNumbers($financeAmount)}</dd>
      <dd>{$_("common.sar")}</dd>
    </dl>

    <dl class="mt-8 flex flex-row items-center text-center flex-1">
      <div class="me-8">
        <dt class="text-sm mb-1">{$_("summary_page.installment")}</dt>
        <dd class="text-base font-bold">{prettyNumbers($installment)}</dd>
        <dd class="text-sm">{$_("common.sar")}</dd>
      </div>
      <div>
        <dt class="text-sm mb-1">{$_("summary_page.tenure")}</dt>
        <dd class="text-base font-bold">{prettyNumbers($tenure)}</dd>
        <dd class="text-sm">{$_("common.year")}</dd>
      </div>
    </dl>

    <button
      class="flex justify-center items-center mt-8 bg-green-100 text-green-700 font-medium py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline"
      on:click={app.firstStep}
    >
      {$_("summary_page.recalculate")}
      <Refresh class="ms-1" />
    </button>

    <FinanceAmountArch />
  </div>
</div>

<div class="mt-6 pt-14 px-4 pb-24 bg-green-100">
  <p class="text-green-700 text-center">
    {$_("summary_page.reportOverline")}
  </p>
  <h1 class="text-2xl font-medium text-center mt-2 mx-6">
    {$_("summary_page.reportTitle")}
  </h1>

  <div
    class="bg-white rounded-3xl shadow-md max-w-lg mt-32 mx-auto relative pt-20"
  >
    <img
      src={illustration}
      alt={$_("summary_page.illustrationAlt")}
      class="absolute ms-4 start-0 -translate-y-full"
    />
    <ul class="p-6">
      <li class="flex">
        <CheckMarkBig />
        {$_("summary_page.feature1")}
      </li>

      <li class="flex mt-4">
        <CheckMarkBig />
        {$_("summary_page.feature2")}
      </li>

      <li class="flex mt-4">
        <CheckMarkBig />
        {$_("summary_page.feature3")}
      </li>
    </ul>

    <hr class="border-t border-gray-200 border-opacity-50" />

    <div class="p-6">
      <h2 class="text-lg font-medium">
        {$_("summary_page.paymentForm.title")}
        {#if !showPayment}
          <span
            in:fly={{ x: -50, duration: 500 }}
            class="text-green-700 whitespace-nowrap inline-block"
            >{window.final_calculation_price} {$_("common.sar")}</span
          >
        {/if}
      </h2>

      {#if showPayment}
        <input
          class="w-full my-4 bg-gray-100 rounded-2xl p-4 text-gray-700 leading-tight focus:outline-none text-lg"
          inputmode="numeric"
          placeholder={$_("common.mobile")}
          on:keyup={(e) =>
            !disableMobileSubmit && e.key === "Enter" && createCalculation()}
          use:formatValueWithState={{
            stateGetter: () => $app.mobile,
            stateSetter: (val) => ($app.mobile = val),
            formatter: (val) => val,
            parser: (val) => convertToEnglishNumbers(val),
          }}
        />
        <button
          disabled={disableMobileSubmit}
          class="bg-green-700 text-white py-4 w-full rounded-2xl disabled:opacity-50 disabled:pointer-events-none flex justify-center items-center"
          on:click={createCalculation}
        >
          {#if $app.calculationStatus === "pending"}
            <Spinner />
          {:else}
            {$_("summary_page.paymentForm.title")}
          {/if}
        </button>
      {:else if $app.calculationStatus === "created"}
        <HyperpayRadioGroup />
      {/if}
    </div>
  </div>
</div>
