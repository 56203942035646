<svg
  viewBox="0 0 42 42"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  {...$$props}
>
  <rect x="1" y="1" width="40" height="40" rx="8" fill="white" />
  <rect
    x="0.5"
    y="0.5"
    width="41"
    height="41"
    rx="8.5"
    stroke="black"
    stroke-opacity="0.07"
  />
  <circle cx="21" cy="21" r="17.5" fill="url(#paint0_linear_396_1128)" />
  <rect x="20.7656" y="4.75" width="0.46875" height="2.96875" fill="white" />
  <rect x="20.7656" y="34.2812" width="0.46875" height="2.96875" fill="white" />
  <rect
    x="17.9473"
    y="5.0376"
    width="0.468751"
    height="2.96875"
    transform="rotate(-10 17.9473 5.0376)"
    fill="white"
  />
  <rect
    width="0.468754"
    height="2.96874"
    transform="matrix(0.984808 -0.173646 0.17365 0.984807 23.0752 34.1201)"
    fill="white"
  />
  <rect
    x="15.2219"
    y="5.81006"
    width="0.468752"
    height="2.96875"
    transform="rotate(-20 15.2219 5.81006)"
    fill="white"
  />
  <rect
    x="25.3223"
    y="33.5605"
    width="0.468749"
    height="2.96874"
    transform="rotate(-20 25.3223 33.5605)"
    fill="white"
  />
  <rect
    width="0.468752"
    height="2.96875"
    transform="matrix(0.939693 0.34202 0.34202 -0.939693 15.2219 36.1899)"
    fill="white"
  />
  <rect
    width="0.468749"
    height="2.96875"
    transform="matrix(0.939692 0.342021 0.342019 -0.939693 25.3223 8.43945)"
    fill="white"
  />
  <rect
    width="0.468751"
    height="2.96874"
    transform="matrix(0.984808 0.173648 0.173648 -0.984808 17.9473 36.9624)"
    fill="white"
  />
  <rect
    width="0.468753"
    height="2.96875"
    transform="matrix(0.984808 0.173647 0.173649 -0.984808 23.0752 7.87988)"
    fill="white"
  />
  <rect
    x="12.6721"
    y="7.04443"
    width="0.46875"
    height="2.96875"
    transform="rotate(-30 12.6721 7.04443)"
    fill="white"
  />
  <rect
    width="0.468749"
    height="2.96876"
    transform="matrix(0.866023 -0.500004 0.499996 0.866028 27.4377 32.6191)"
    fill="white"
  />
  <rect
    width="0.46875"
    height="2.96874"
    transform="matrix(0.866025 0.5 0.5 -0.866025 12.6721 34.9556)"
    fill="white"
  />
  <rect
    width="0.468748"
    height="2.96875"
    transform="matrix(0.866024 0.500002 0.499998 -0.866026 27.4377 9.38086)"
    fill="white"
  />
  <rect
    x="10.3752"
    y="8.70264"
    width="0.468749"
    height="2.96875"
    transform="rotate(-40 10.3752 8.70264)"
    fill="white"
  />
  <rect
    width="0.46875"
    height="2.96875"
    transform="matrix(0.766045 -0.642787 0.642788 0.766044 29.3574 31.3247)"
    fill="white"
  />
  <rect
    width="0.46875"
    height="2.96875"
    transform="matrix(0.766044 0.642788 0.642788 -0.766044 10.3752 33.2974)"
    fill="white"
  />
  <rect
    width="0.46875"
    height="2.96875"
    transform="matrix(0.766044 0.642788 0.642787 -0.766045 29.3574 10.6753)"
    fill="white"
  />
  <rect
    x="8.40112"
    y="10.7344"
    width="0.468751"
    height="2.96875"
    transform="rotate(-50 8.40112 10.7344)"
    fill="white"
  />
  <rect
    width="0.46875"
    height="2.96875"
    transform="matrix(0.642787 -0.766045 0.766044 0.642789 31.0234 29.7168)"
    fill="white"
  />
  <rect
    width="0.468749"
    height="2.96874"
    transform="matrix(0.642787 0.766045 0.766044 -0.642788 8.40112 31.2656)"
    fill="white"
  />
  <rect
    width="0.46875"
    height="2.96875"
    transform="matrix(0.642787 0.766045 0.766044 -0.642788 31.0234 12.2832)"
    fill="white"
  />
  <rect
    x="6.80981"
    y="13.0781"
    width="0.468749"
    height="2.96875"
    transform="rotate(-60 6.80981 13.0781)"
    fill="white"
  />
  <rect
    width="0.46875"
    height="2.96876"
    transform="matrix(0.500002 -0.866024 0.866027 0.499998 32.3845 27.8438)"
    fill="white"
  />
  <rect
    width="0.468751"
    height="2.96875"
    transform="matrix(0.5 0.866025 0.866025 -0.5 6.80981 28.9219)"
    fill="white"
  />
  <rect
    width="0.468751"
    height="2.96876"
    transform="matrix(0.500001 0.866025 0.866026 -0.499999 32.3845 14.1562)"
    fill="white"
  />
  <rect
    x="5.6499"
    y="15.6626"
    width="0.468752"
    height="2.96876"
    transform="rotate(-70 5.6499 15.6626)"
    fill="white"
  />
  <rect
    x="33.4001"
    y="25.7627"
    width="0.468748"
    height="2.96874"
    transform="rotate(-70 33.4001 25.7627)"
    fill="white"
  />
  <rect
    width="0.468749"
    height="2.96875"
    transform="matrix(0.34202 0.939693 0.939693 -0.34202 5.6499 26.3374)"
    fill="white"
  />
  <rect
    width="0.468747"
    height="2.96874"
    transform="matrix(0.342022 0.939692 0.939693 -0.342018 33.4001 16.2373)"
    fill="white"
  />
  <rect
    x="4.95605"
    y="18.4092"
    width="0.46875"
    height="2.96875"
    transform="rotate(-80 4.95605 18.4092)"
    fill="white"
  />
  <rect
    width="0.468753"
    height="2.96875"
    transform="matrix(0.173647 -0.984808 0.984807 0.17365 34.0386 23.5371)"
    fill="white"
  />
  <rect
    width="0.468748"
    height="2.96875"
    transform="matrix(0.173648 0.984808 0.984808 -0.173648 4.95605 23.5908)"
    fill="white"
  />
  <rect
    width="0.46875"
    height="2.96875"
    transform="matrix(0.173648 0.984808 0.984808 -0.173648 34.0386 18.4629)"
    fill="white"
  />
  <rect
    x="4.75"
    y="21.2344"
    width="0.46875"
    height="2.96875"
    transform="rotate(-90 4.75 21.2344)"
    fill="white"
  />
  <rect
    x="34.2812"
    y="21.2344"
    width="0.46875"
    height="2.96875"
    transform="rotate(-90 34.2812 21.2344)"
    fill="white"
  />
  <rect
    x="19.3503"
    y="4.83203"
    width="0.468745"
    height="1.875"
    transform="rotate(-5 19.3503 4.83203)"
    fill="white"
  />
  <rect
    width="0.468742"
    height="1.875"
    transform="matrix(0.996195 -0.087157 0.0871544 0.996195 22.0195 35.3403)"
    fill="white"
  />
  <rect
    x="22.1829"
    y="4.7915"
    width="0.468751"
    height="1.875"
    transform="rotate(5 22.1829 4.7915)"
    fill="white"
  />
  <rect
    x="19.5137"
    y="35.2998"
    width="0.468749"
    height="1.875"
    transform="rotate(5.00001 19.5137 35.2998)"
    fill="white"
  />
  <rect
    x="16.5679"
    y="5.36426"
    width="0.46875"
    height="1.875"
    transform="rotate(-15 16.5679 5.36426)"
    fill="white"
  />
  <rect
    width="0.468751"
    height="1.875"
    transform="matrix(0.965926 -0.258819 0.25882 0.965926 24.4941 34.9458)"
    fill="white"
  />
  <rect
    width="0.468752"
    height="1.87501"
    transform="matrix(0.965926 0.258819 0.258819 -0.965926 16.5679 36.6357)"
    fill="white"
  />
  <rect
    width="0.468745"
    height="1.875"
    transform="matrix(0.965925 0.258821 0.258817 -0.965926 24.4941 7.0542)"
    fill="white"
  />
  <rect
    x="13.9199"
    y="6.37158"
    width="0.46875"
    height="1.875"
    transform="rotate(-25 13.9199 6.37158)"
    fill="white"
  />
  <rect
    width="0.468751"
    height="1.875"
    transform="matrix(0.906308 -0.422617 0.422619 0.906307 26.8625 34.1274)"
    fill="white"
  />
  <rect
    width="0.46875"
    height="1.875"
    transform="matrix(0.906308 0.422618 0.422618 -0.906308 13.9199 35.6284)"
    fill="white"
  />
  <rect
    width="0.468751"
    height="1.875"
    transform="matrix(0.906308 0.422618 0.422619 -0.906308 26.8625 7.87256)"
    fill="white"
  />
  <rect
    x="11.4873"
    y="7.82324"
    width="0.468751"
    height="1.875"
    transform="rotate(-35 11.4873 7.82324)"
    fill="white"
  />
  <rect
    width="0.468751"
    height="1.87501"
    transform="matrix(0.819151 -0.573578 0.573575 0.819153 29.053 32.9097)"
    fill="white"
  />
  <rect
    width="0.468751"
    height="1.875"
    transform="matrix(0.819152 0.573576 0.573577 -0.819152 11.4873 34.1768)"
    fill="white"
  />
  <rect
    width="0.468751"
    height="1.875"
    transform="matrix(0.819153 0.573575 0.573578 -0.819151 29.053 9.09033)"
    fill="white"
  />
  <rect
    x="9.34375"
    y="9.67529"
    width="0.468749"
    height="1.875"
    transform="rotate(-45 9.34375 9.67529)"
    fill="white"
  />
  <rect
    width="0.468748"
    height="1.87499"
    transform="matrix(0.707101 -0.707112 0.707101 0.707112 30.999 31.3306)"
    fill="white"
  />
  <rect
    x="7.55444"
    y="11.8716"
    width="0.46875"
    height="1.875"
    transform="rotate(-55 7.55444 11.8716)"
    fill="white"
  />
  <rect
    width="0.468748"
    height="1.87499"
    transform="matrix(0.573578 -0.819151 0.819153 0.573575 32.6411 29.4375)"
    fill="white"
  />
  <rect
    width="0.468747"
    height="1.87499"
    transform="matrix(0.573576 0.819152 0.819152 -0.573576 7.55444 30.1284)"
    fill="white"
  />
  <rect
    width="0.46875"
    height="1.875"
    transform="matrix(0.573577 0.819152 0.819152 -0.573576 32.6411 12.5625)"
    fill="white"
  />
  <rect
    x="6.17334"
    y="14.3447"
    width="0.46875"
    height="1.875"
    transform="rotate(-65 6.17334 14.3447)"
    fill="white"
  />
  <rect
    x="33.929"
    y="27.2876"
    width="0.468749"
    height="1.875"
    transform="rotate(-65 33.929 27.2876)"
    fill="white"
  />
  <rect
    width="0.468749"
    height="1.875"
    transform="matrix(0.422618 0.906308 0.906308 -0.422618 6.17334 27.6553)"
    fill="white"
  />
  <rect
    width="0.468749"
    height="1.875"
    transform="matrix(0.422618 0.906308 0.906308 -0.422618 33.929 14.7124)"
    fill="white"
  />
  <rect
    x="5.24292"
    y="17.0205"
    width="0.468749"
    height="1.875"
    transform="rotate(-75 5.24292 17.0205)"
    fill="white"
  />
  <rect
    width="0.468756"
    height="1.87501"
    transform="matrix(0.258817 -0.965926 0.965925 0.258821 34.8245 24.9468)"
    fill="white"
  />
  <rect
    width="0.468751"
    height="1.875"
    transform="matrix(0.258819 0.965926 0.965926 -0.258819 5.24292 24.9795)"
    fill="white"
  />
  <rect
    width="0.46875"
    height="1.87501"
    transform="matrix(0.25882 0.965925 0.965926 -0.258818 34.8245 17.0532)"
    fill="white"
  />
  <rect
    x="4.7915"
    y="19.8174"
    width="0.468751"
    height="1.875"
    transform="rotate(-85 4.7915 19.8174)"
    fill="white"
  />
  <rect
    x="35.3"
    y="22.4863"
    width="0.468751"
    height="1.875"
    transform="rotate(-85 35.3 22.4863)"
    fill="white"
  />
  <rect
    width="0.468751"
    height="1.875"
    transform="matrix(0.0871557 0.996195 0.996195 -0.0871557 4.7915 22.1826)"
    fill="white"
  />
  <rect
    width="0.468749"
    height="1.875"
    transform="matrix(0.0871556 0.996195 0.996195 -0.0871559 35.3 19.5137)"
    fill="white"
  />
  <rect
    x="4.83228"
    y="22.6499"
    width="0.468751"
    height="1.875"
    transform="rotate(-95 4.83228 22.6499)"
    fill="white"
  />
  <rect
    width="0.468755"
    height="1.875"
    transform="matrix(-0.0871546 -0.996195 0.996195 -0.0871569 35.3408 19.981)"
    fill="white"
  />
  <rect
    width="0.468745"
    height="1.875"
    transform="matrix(-0.0871557 0.996195 0.996195 0.0871558 4.83228 19.3501)"
    fill="white"
  />
  <rect
    width="0.468742"
    height="1.875"
    transform="matrix(-0.087157 0.996195 0.996195 0.0871544 35.3408 22.019)"
    fill="white"
  />
  <g filter="url(#filter0_d_396_1128)">
    <path
      d="M32.4904 9.50964L22.1047 22.105L19.895 19.8953L32.4904 9.50964Z"
      fill="#FE3C30"
    />
    <path
      d="M9.50939 32.4906L22.1047 22.105L19.895 19.8953L9.50939 32.4906Z"
      fill="white"
    />
  </g>
  <defs>
    <filter
      id="filter0_d_396_1128"
      x="7.60303"
      y="9.50977"
      width="26.7935"
      height="26.7935"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dy="1.90625" />
      <feGaussianBlur stdDeviation="0.953125" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
      />
      <feBlend
        mode="normal"
        in2="BackgroundImageFix"
        result="effect1_dropShadow_396_1128"
      />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="effect1_dropShadow_396_1128"
        result="shape"
      />
    </filter>
    <linearGradient
      id="paint0_linear_396_1128"
      x1="21"
      y1="3.55385"
      x2="21"
      y2="38.4462"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#1BC9FA" />
      <stop offset="0.760051" stop-color="#1F70F0" />
    </linearGradient>
  </defs>
</svg>
