<script>
  import { app } from "../store";
  import Chevron from "./chevron.svelte";
  import Spinner from "../components/spinner.svelte";
  export let nextButtonLabel;
  export let hideNext;
  export let hidePrev;
  export let center = false;
  export let disableNext = false;
  export let loading = false;
  export let onNext = null;
  export let divider = true;
</script>

<div
  class="fixed bottom-0 left-0 right-0 flex p-4 border-t border-gray-200 backdrop-blur-md bg-white/50"
  class:justify-between={!center}
  class:justify-center={center}
  class:border-transparent={!divider}
>
  {#if !hidePrev}
    <button class="p-4 bg-gray-200 rounded-2xl" on:click={app.prevStep}>
      <Chevron direction="back" />
    </button>
  {/if}
  {#if !hideNext}
    <button
      class="bg-green-700 text-white py-4 ps-10 pe-6 flex items-center rounded-2xl whitespace-nowrap"
      class:opacity-50={disableNext || loading}
      disabled={disableNext || loading}
      on:click={onNext ? onNext : app.nextStep}
    >
      {#if loading}
        <Spinner />
      {:else}
        {nextButtonLabel}
        <Chevron direction="forward" />
      {/if}
    </button>
  {/if}
</div>
