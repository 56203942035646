import CopyToClipboard from "../real_estate/components/copy_to_clipboard.svelte";

export function prettyNumbers(number) {
  if (number === null || number === "") return number;

  return typeof number === "number"
    ? number.toLocaleString("en-US", { maximumFractionDigits: 0 })
    : prettyNumbers(parseInt(number));
}


export function convertToEnglishNumbers(number) {
  if (number === null || number === "") return number;

  return number.toString().replace(/[\u0660-\u0669]/g, (match) => {
    return parseInt(match.charCodeAt(0) - 0x0660);
  });
}

export function integerFromFormattedStringOrNull(string) {
  const parsed = parseInt(string.replace(/[^0-9]/g, ""));

  return isNaN(parsed) ? null : parsed;
}

export function encodeUserState(state) {
  return Buffer.from(JSON.stringify(state)).toString("base64");
}

export function decodeUserState(base64State) {
  try {
    const userStateJson = Buffer.from(base64State, "base64").toString("ascii");

    return JSON.parse(userStateJson);
  } catch {
    return {};
  }
}

export function decodeUserStateQueryParameter(queryParams) {
  try {
    const urlUserState = new URLSearchParams(queryParams);
    const urlUserStateEncoded = urlUserState.get("user_state");

    return decodeUserState(urlUserStateEncoded);
  } catch {
    return {};
  }
}

export function copyToClipboard(text) {
  // See: https://svelte.dev/repl/3194502389af4e22991f8bb0c61d65cf?version=3.16.5
  const clipboardTextArea = new CopyToClipboard({
    target: document.body,
    props: { text },
  });
  clipboardTextArea.$destroy();
}
