<svg
  width="76"
  height="76"
  viewBox="0 0 76 76"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M37.7625 74.1C23.0316 74.1 7.125 64.8613 7.125 44.5847C7.125 24.3081 23.0316 15.0694 37.7625 15.0694C45.9444 15.0694 53.4969 17.7591 59.0959 22.6694C65.1641 28.0606 68.3881 35.6606 68.3881 44.5847C68.3881 53.5088 65.1641 61.0613 59.0959 66.4466C53.4969 71.3569 45.8909 74.1 37.7625 74.1Z"
    fill="url(#paint0_radial_293_2222)"
  />
  <path
    d="M63.0505 27.0394C65.948 31.8487 67.4323 37.3825 67.3314 42.9994C67.3314 51.9056 64.1073 59.4759 58.0392 64.8612C52.4402 69.7715 44.8402 72.5147 36.7058 72.5147C27.1702 72.5147 17.1536 68.6375 11.2695 60.4022C16.9517 69.73 27.6333 74.1 37.7627 74.1C45.8911 74.1 53.497 71.3569 59.0961 66.4466C65.1642 61.0553 68.3883 53.5087 68.3883 44.5847C68.3883 37.8575 66.5536 31.8784 63.0505 27.0394Z"
    fill="#EB8F00"
  />
  <path
    opacity="0.8"
    d="M20.0684 52.3094C25.8069 52.3094 30.459 47.9232 30.459 42.5125C30.459 37.1018 25.8069 32.7156 20.0684 32.7156C14.3298 32.7156 9.67773 37.1018 9.67773 42.5125C9.67773 47.9232 14.3298 52.3094 20.0684 52.3094Z"
    fill="url(#paint1_radial_293_2222)"
  />
  <path
    opacity="0.8"
    d="M55.9312 52.3094C61.6697 52.3094 66.3218 47.9232 66.3218 42.5125C66.3218 37.1018 61.6697 32.7156 55.9312 32.7156C50.1926 32.7156 45.5405 37.1018 45.5405 42.5125C45.5405 47.9232 50.1926 52.3094 55.9312 52.3094Z"
    fill="url(#paint2_radial_293_2222)"
  />
  <path
    d="M14.25 11.875C16.5454 11.875 18.4062 10.5458 18.4062 8.90625C18.4062 7.26665 16.5454 5.9375 14.25 5.9375C11.9546 5.9375 10.0938 7.26665 10.0938 8.90625C10.0938 10.5458 11.9546 11.875 14.25 11.875Z"
    fill="#FF00A2"
  />
  <path
    d="M31.7337 17.379C32.7076 16.4051 32.4632 14.5818 31.1879 13.3065C29.9125 12.0312 28.0892 11.7869 27.1154 12.7608C26.1415 13.7347 26.3859 15.558 27.6612 16.8333C28.9366 18.1086 30.7599 18.3529 31.7337 17.379Z"
    fill="#FD3B3B"
  />
  <path
    d="M44.4702 12.6445C46.6485 10.9585 47.7797 8.77188 46.9969 7.76055C46.2142 6.74921 43.8138 7.29612 41.6355 8.98211C39.4572 10.6681 38.326 12.8547 39.1088 13.866C39.8915 14.8774 42.2919 14.3305 44.4702 12.6445Z"
    fill="#00EEFF"
  />
  <path
    d="M15.4126 51.8379C16.2361 51.3624 16.0398 49.4806 14.9741 47.6347C13.9083 45.7888 12.3768 44.6778 11.5532 45.1533C10.7296 45.6288 10.926 47.5106 11.9917 49.3565C13.0574 51.2024 14.589 52.3134 15.4126 51.8379Z"
    fill="#0048FF"
  />
  <path
    d="M57.2969 68.875C58.4446 68.875 59.375 67.4129 59.375 65.6094C59.375 63.8058 58.4446 62.3438 57.2969 62.3438C56.1492 62.3438 55.2188 63.8058 55.2188 65.6094C55.2188 67.4129 56.1492 68.875 57.2969 68.875Z"
    fill="#F63BBE"
  />
  <path
    d="M69.1914 34.8716C70.9344 33.6447 72.0413 32.2153 71.6638 31.679C71.2863 31.1428 69.5673 31.7026 67.8243 32.9295C66.0814 34.1564 64.9745 35.5858 65.352 36.1221C65.7295 36.6584 67.4485 36.0985 69.1914 34.8716Z"
    fill="#00CC00"
  />
  <path
    d="M13.3474 71.8654C14.0328 71.4157 13.7135 69.7175 12.6342 68.0725C11.5549 66.4274 10.1243 65.4584 9.43885 65.9081C8.75341 66.3578 9.07272 68.056 10.152 69.701C11.2314 71.3461 12.662 72.3151 13.3474 71.8654Z"
    fill="#0048FF"
  />
  <path
    d="M9.01093 60.2626C10.9735 60.1236 12.5176 59.348 12.4596 58.5302C12.4017 57.7125 10.7638 57.1623 8.80115 57.3013C6.83856 57.4403 5.29452 58.216 5.35245 59.0337C5.41038 59.8515 7.04833 60.4017 9.01093 60.2626Z"
    fill="#7ACDED"
  />
  <path
    d="M14.3203 52.4864C16.6761 52.3299 18.556 51.7521 18.519 51.1959C18.482 50.6396 16.5423 50.3156 14.1865 50.4721C11.8307 50.6286 9.95086 51.2064 9.98781 51.7626C10.0248 52.3189 11.9645 52.6429 14.3203 52.4864Z"
    fill="#FF82B2"
  />
  <path
    d="M70.5965 46.4313C70.0918 44.6856 68.8865 43.225 67.2715 42.3938C64.5402 40.9094 61.1202 41.9247 59.6418 44.6559C59.6299 44.6738 59.624 44.6916 59.6121 44.7094C59.0184 45.8284 58.8912 47.1367 59.2582 48.3491C59.6253 49.5614 60.4568 50.5795 61.5715 51.1813C62.4918 51.6978 63.5724 51.8463 64.5996 51.5969C65.6565 51.3594 66.5471 50.6647 67.034 49.6969C67.5624 48.5806 67.5149 47.2744 66.9152 46.1938C66.6761 45.7268 66.3214 45.3288 65.8849 45.0378C65.4483 44.7467 64.9445 44.5724 64.4215 44.5313C63.8099 44.5194 63.3112 45.0063 63.2993 45.6178C63.2934 46.17 63.6971 46.645 64.2434 46.7281C64.5462 46.7459 64.8193 46.93 64.9559 47.2031C65.1637 47.6247 65.2052 48.1175 65.0746 48.5688C64.9757 48.7654 64.8335 48.937 64.6587 49.0707C64.4838 49.2044 64.2809 49.2967 64.0652 49.3406C63.5843 49.4653 63.0737 49.4 62.6402 49.1625C62.0465 48.8478 61.6012 48.3134 61.3934 47.6781C61.2152 47.025 61.2805 46.3303 61.5715 45.7188C61.7767 45.3186 62.0617 44.9646 62.4089 44.6787C62.7561 44.3928 63.1581 44.1809 63.5902 44.0563C64.463 43.8069 65.3893 43.89 66.2027 44.2938C67.2655 44.8756 68.0552 45.8613 68.3996 47.025C68.7618 48.1947 68.6312 49.4594 68.0434 50.5281C66.9152 52.6656 64.7184 54.0906 61.5121 54.0906C59.6715 54.0906 48.7168 53.7938 48.7168 53.7938L48.8474 56.525L61.3934 56.3469C65.134 56.3469 68.2215 54.8625 70.0027 51.5375C70.9409 50.0056 71.1546 48.1353 70.5965 46.4313Z"
    fill="url(#paint3_linear_293_2222)"
  />
  <path
    d="M41.5625 55.2188C41.9247 55.6047 42.2037 56.056 42.3937 56.5488L49.1922 56.5072C49.1922 55.6166 49.0734 54.726 49.0734 53.776C47.2922 53.7166 43.6228 53.6572 42.4828 53.6157C42.319 54.2283 41.988 54.7834 41.5269 55.2188H41.5625Z"
    fill="#7F0099"
  />
  <path
    d="M62.2247 32.5375L64.3028 32.8344L64.8372 30.7562L65.1341 29.5687L53.1997 27.9062C58.6028 32.0031 61.8685 32.5375 62.2247 32.5375Z"
    fill="#FF4545"
  />
  <path
    d="M71.784 3.2063L66.9746 5.93755L65.7871 6.59067L70.7746 7.30317C71.1309 5.8188 71.784 3.2063 71.784 3.2063Z"
    fill="#FF4545"
  />
  <path
    d="M61.1557 9.20312L56.2275 11.9938L69.1119 13.8344L69.9432 10.45L61.1557 9.20312Z"
    fill="#FF4545"
  />
  <path
    d="M47.0838 17.1594H47.0244L67.5088 20.1281L68.2807 16.9813L51.5963 14.6063L47.0838 17.1594Z"
    fill="#FF4545"
  />
  <path
    d="M48.8057 23.9875L65.9057 26.4219L66.7369 23.2156L46.1338 20.2469C46.3713 20.9 47.0838 22.2063 48.8057 23.9875Z"
    fill="#FF4545"
  />
  <path
    d="M51.5967 14.6063L68.2811 16.9813L69.1123 13.8344L56.2279 11.9938L51.5967 14.6063Z"
    fill="#FF9C9C"
  />
  <path
    d="M70.4186 8.6094C70.4186 8.6094 70.5967 7.77815 70.7154 7.36252L65.7279 6.65002L61.0967 9.26252L69.8842 10.5094L70.4186 8.6094Z"
    fill="#FF9C9C"
  />
  <path
    d="M45.3027 18.1094L45.9559 20.0094C45.9796 20.1163 46.0212 20.2172 46.0746 20.3063L66.6777 23.275L67.4496 20.1281L46.9652 17.2188L45.3027 18.1094Z"
    fill="#FF9C9C"
  />
  <path
    d="M52.9619 27.7281C53.0213 27.7875 53.14 27.8469 53.1994 27.9062L65.1338 29.6281L65.9057 26.4812L48.8057 24.0469C50.106 25.365 51.4954 26.5941 52.9619 27.7281Z"
    fill="#FF9C9C"
  />
  <path
    d="M71.7245 6.7094C73.2985 6.7094 74.5745 5.43341 74.5745 3.8594C74.5745 2.28539 73.2985 1.0094 71.7245 1.0094C70.1505 1.0094 68.8745 2.28539 68.8745 3.8594C68.8745 5.43341 70.1505 6.7094 71.7245 6.7094Z"
    fill="#C93737"
  />
  <path
    d="M42.6549 54.5241C43.5282 53.769 44.0751 52.705 44.1808 51.5554C44.3471 50.3975 44.0027 49.2279 43.2308 48.3491C41.8474 46.5322 37.9999 46.9835 37.9999 46.9835C36.9668 47.025 35.7733 47.5179 35.9218 48.7054C36.0174 49.04 36.2012 49.3428 36.454 49.582C36.7068 49.8212 37.0192 49.988 37.3586 50.065C38.8786 50.4272 41.0458 50.065 41.1052 51.6088C41.1765 53.3425 38.5165 52.63 38.3027 54.4647C38.089 56.2994 41.1052 56.056 41.1052 57.3207C41.1052 58.2885 40.1136 58.4547 39.0686 58.6744C38.1721 58.8585 36.4502 59.1375 36.3136 60.1647C36.1771 61.1919 36.7649 62.4566 39.1874 62.0885C42.9993 61.4947 44.2818 59.4225 44.2818 58.14C44.3055 56.7507 43.7118 55.4266 42.6549 54.5241Z"
    fill="#422B0D"
  />
  <path
    d="M29.6042 38.8491L29.5151 38.7363L29.2776 38.4335L28.9748 38.1069C28.8561 37.9763 28.7136 37.8338 28.5711 37.6913C28.4226 37.5488 28.2683 37.4122 28.102 37.2875C27.9536 37.1747 27.7992 37.0738 27.633 36.9847C27.5201 36.9194 27.3955 36.8719 27.2648 36.8482C27.2255 36.8422 27.1854 36.8422 27.1461 36.8482H27.0986H27.2055H26.8967C26.8195 36.8482 26.8967 36.8482 26.8967 36.8482H26.9383C26.9858 36.8482 26.9383 36.8482 26.9383 36.8482C26.9169 36.8422 26.8943 36.8422 26.873 36.8482C26.7423 36.8719 26.6176 36.9194 26.5048 36.9847C26.3386 37.0738 26.1842 37.1747 26.0358 37.2875C25.8814 37.4063 25.7211 37.5488 25.5667 37.6913C25.2758 37.9763 25.0205 38.285 24.8423 38.4691L24.5573 38.8075L24.4267 38.956C24.0361 39.3883 23.5001 39.6617 22.9208 39.7241C22.3415 39.7866 21.7595 39.6336 21.2858 39.2944C20.6623 38.861 20.3417 38.1069 20.4605 37.3528C20.4605 37.3528 20.502 37.1272 20.6089 36.7591C20.7751 36.1891 21.0126 35.6428 21.3095 35.1322C21.7726 34.2891 22.402 33.5469 23.1561 32.9532C23.6726 32.5613 24.2426 32.2466 24.8542 32.0269C25.0205 31.9616 25.1986 31.9082 25.3708 31.8666C25.5667 31.8072 25.7626 31.7657 25.9645 31.736L26.3089 31.6885H27.627C27.8111 31.6885 28.0011 31.736 28.1851 31.7657C29.1861 31.9468 30.13 32.3618 30.9401 32.9769C31.7061 33.5588 32.3533 34.2891 32.8283 35.1263C33.0005 35.4172 33.1548 35.726 33.2795 36.0407C33.3923 36.3019 33.4755 36.581 33.5467 36.7888C33.618 36.9966 33.612 37.0975 33.6358 37.1985V37.335C33.7123 37.8861 33.5671 38.445 33.2321 38.8891C32.897 39.3333 32.3995 39.6264 31.8486 39.7041C31.8248 39.71 31.8011 39.71 31.7833 39.71C30.958 39.8347 30.1208 39.5082 29.6042 38.8491Z"
    fill="#422B0D"
  />
  <path
    d="M51.6561 38.8491L51.5671 38.7363L51.3296 38.4335L51.0624 38.1069C50.9436 37.9763 50.8011 37.8338 50.6586 37.6913C50.5102 37.5488 50.3558 37.4122 50.1896 37.2875C50.0411 37.1747 49.8868 37.0738 49.7205 36.9847C49.6077 36.9194 49.483 36.8719 49.3583 36.8482C49.319 36.8422 49.2789 36.8422 49.2396 36.8482H49.1921H49.2811H48.9724C48.8893 36.8482 48.9724 36.8482 48.9724 36.8482H49.014C49.0615 36.8482 49.014 36.8482 49.014 36.8482H48.9486C48.824 36.8719 48.6993 36.9194 48.5865 36.9847C48.4202 37.0738 48.2658 37.1747 48.1174 37.2875C47.9511 37.4122 47.7968 37.5488 47.6483 37.6913C47.3515 37.9763 47.1021 38.285 46.918 38.4691L46.633 38.8075L46.5024 38.956C46.1118 39.3883 45.5757 39.6617 44.9965 39.7241C44.4172 39.7866 43.8352 39.6336 43.3614 39.2944C42.738 38.861 42.4174 38.1069 42.5361 37.3528C42.5361 37.3528 42.5777 37.1272 42.6905 36.7591C42.839 36.1832 43.0586 35.625 43.3436 35.1025C43.8186 34.2654 44.4658 33.535 45.2318 32.9532C45.7483 32.5613 46.3243 32.2466 46.9358 32.0269C47.1021 31.9616 47.2743 31.9082 47.4465 31.8666C47.6424 31.8072 47.8383 31.7657 48.0402 31.736L48.3846 31.6885H49.7027C49.8927 31.6885 50.0768 31.736 50.2608 31.7657C51.2643 31.9379 52.2083 32.3475 53.0218 32.9532C53.7877 33.535 54.429 34.2654 54.9099 35.1025C55.0821 35.3935 55.2305 35.7022 55.3552 36.0169C55.4621 36.2604 55.5511 36.5097 55.6224 36.765C55.664 36.9016 55.6936 37.0382 55.7115 37.1747V37.3113C55.7507 37.5827 55.7361 37.8591 55.6684 38.1249C55.6007 38.3906 55.4813 38.6403 55.3169 38.8598C55.1526 39.0794 54.9465 39.2643 54.7106 39.4041C54.4747 39.5438 54.2135 39.6357 53.9421 39.6744C53.9183 39.6803 53.8946 39.6803 53.8649 39.6863C53.0336 39.8169 52.1905 39.5022 51.6561 38.8491Z"
    fill="#422B0D"
  />
  <defs>
    <radialGradient
      id="paint0_radial_293_2222"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(37.7566 44.5847) scale(30.0788)"
    >
      <stop offset="0.5" stop-color="#FDE030" />
      <stop offset="0.92" stop-color="#F7C02B" />
      <stop offset="1" stop-color="#F4A223" />
    </radialGradient>
    <radialGradient
      id="paint1_radial_293_2222"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(20.0686 44.6018) scale(11.3093 10.1553)"
    >
      <stop stop-color="#ED7770" />
      <stop offset="0.9" stop-color="#ED7770" stop-opacity="0" />
    </radialGradient>
    <radialGradient
      id="paint2_radial_293_2222"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(75.1212 696.225) scale(11.5366)"
    >
      <stop stop-color="#ED7770" />
      <stop offset="0.9" stop-color="#ED7770" stop-opacity="0" />
    </radialGradient>
    <linearGradient
      id="paint3_linear_293_2222"
      x1="48.7999"
      y1="49.1186"
      x2="70.8815"
      y2="49.1186"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#004054" />
      <stop offset="0.99" stop-color="#45A4FF" />
    </linearGradient>
  </defs>
</svg>
