<script>
  import { post } from "axios";
  import { onMount } from "svelte";
  import { _ } from "svelte-i18n";
  import { reportStore, app, finalCalculation, form } from "../store";
  import ReportNav from "../components/report_nav.svelte";
  import { prettyNumbers } from "../../lib/utils";
  import LogoSmall from "../assets/logo_small.svelte";
  import PdfLogo from "../assets/pdf_logo.svelte";
  import BeachLogo from "../assets/beach_logo.svelte";
  import FiannceAmountArch from "../assets/finance_amount_arch.svelte";
  import AlrajhiBankLogo from "../assets/bank_logo_alrajhi.svelte";
  import Spinner from "../components/spinner.svelte";

  onMount(() => {
    $app.finalCalculationStatus = "pending";
    post(window.final_calculation_path, {
      id: $app.report_id,
    })
      .then((response) => {
        $finalCalculation = response.data.final_calculation;
        $form = response.data.calculator_params;
        $app.finalCalculationStatus = "created";
      })
      .catch((error) => {
        console.log(error);
        $app.finalCalculationStatus = "error";
      });
  });

  $: hasObligations =
    $form.existing_finances.length > 0 ||
    $form.existing_credit_card_limits.length > 0;
</script>

<div>
  {#if $app.finalCalculationStatus === null || $app.finalCalculationStatus === "pending"}
    <div class="w-screen h-screen flex flex-col justify-center items-center">
      <Spinner color="black" />
      <span class="mt-4 text-gray-800">{$_("report_page.loadingReport")}</span>
    </div>
  {:else if $app.finalCalculationStatus === "created"}
    <header>
      <div class="flex justify-between items-center">
        <div class="px-8 flex items-center pt-20 pb-2">
          <LogoSmall />
          <div class="flex flex-col ms-2">
            <span class="text-xs">{$_("report_page.logoOverline")}</span>
            <span class="font-semibold text-lg leading-5"
              >{$_("report_page.logoTitle")}</span
            >
          </div>
        </div>
        <div class="px-8 flex items-center pt-20 pb-2">
          <a
            href={`${window.report_pdf_path}?id=${$app.report_id}`}
            class="py-2 px-4 text-white flex items-center justify-between bg-green-700 text-sm rounded-lg"
          >
            <span class="pe-2">{$_("report_page.download_pdf")}</span>
            <PdfLogo />
          </a>
        </div>
      </div>
      <ReportNav />
    </header>

    {#if $reportStore.tab === 0}
      <main class="p-6">
        <h2 class="font-medium mb-2">{$_("report_page.salary.title")}</h2>
        <dl class="rounded-2xl border border-gray-200 p-4 space-y-4">
          <div>
            <dt class="text-sm text-gray-700 mb-1">
              {$_("report_page.salary.monthly")}
            </dt>
            <dd class="font-medium">
              {prettyNumbers($form.salary)}
              {$_("common.sar")}
            </dd>
          </div>
          <hr class="border-gray-200" />
          <div>
            <dt class="text-sm text-gray-700 mb-1">
              {$_("report_page.salary.sector")}
            </dt>
            <dd class="font-medium">
              {$_(`common.employer.${$form.employer}`)}
              {#if $form.employer === "military"}
                · {$_(`common.employer.ranks.${$form.rank}`)}
              {/if}
            </dd>
          </div>
        </dl>

        {#if hasObligations}
          <h2 class="font-medium mt-8 mb-2">
            {$_("report_page.obligations.title")}
          </h2>
          <dl class="rounded-2xl border border-gray-200 p-4 space-y-4">
            {#each $form.existing_finances as finance, index}
              <div>
                <dt class="text-sm text-gray-700 mb-1">
                  {$_("report_page.obligations.obligation")}
                  {index + 1}
                </dt>
                <dd class="font-medium">
                  {finance.monthly_installment}
                  {$_("common.sar")} • {finance.remaining_months}
                  {$_("common.months")}
                </dd>
              </div>
              <hr class="border-gray-200 last:hidden" />
            {/each}

            {#each $form.existing_credit_card_limits as card_limit, index}
              <div>
                <dt class="text-sm text-gray-700 mb-1">
                  {$_("report_page.obligations.creditCardLimit")}
                  {index + 1}
                </dt>
                <dd class="font-medium">
                  {card_limit}
                  {$_("common.sar")}
                </dd>
              </div>
              <hr class="border-gray-200 last:hidden" />
            {/each}
          </dl>
        {/if}

        <h2 class="font-medium mt-8 mb-2">
          {$_("report_page.personal.title")}
        </h2>
        <dl class="rounded-2xl border border-gray-200 p-4 space-y-4">
          <div>
            <dt class="text-sm text-gray-700 mb-1">
              {$_("report_page.personal.age")}
            </dt>
            <dd class="font-medium">{$form.age} {$_("common.year")}</dd>
          </div>
          <hr class="border-gray-200" />
          <div>
            <dt class="text-sm text-gray-700 mb-1">
              {$_("report_page.personal.redf")}
            </dt>
            <dd class="font-medium">
              {$form.redf ? $_("common.yes") : $_("common.no")}
            </dd>
          </div>
        </dl>
      </main>
    {:else if $reportStore.tab === 1}
      <main class="pt-20 px-6 pb-6">
        <div class="relative flex flex-col items-center">
          <AlrajhiBankLogo />

          <dl class="text-center mt-6 flex flex-col space-y-2">
            <dt class="text-base font-medium">
              {$_("summary_page.financeAmount")}
            </dt>
            <dd class="text-3xl font-bold">
              {prettyNumbers($finalCalculation.amount)}
            </dd>
            <dd>{$_("common.sar")}</dd>
          </dl>

          <FiannceAmountArch />
        </div>

        <dl class="mt-10 rounded-2xl border border-gray-200 p-4 space-y-4">
          {#each Object.entries($finalCalculation.monthly_installment) as [amount, months]}
            <div>
              <dt class="text-sm text-gray-700 mb-1">
                {$_("report_page.installmentFor")}
                {months}
                {$_("common.months")}
              </dt>
              <dd class="font-medium">
                {prettyNumbers(amount)}
                {$_("common.sar")}
              </dd>
            </div>
            <hr class="border-gray-200 last:hidden" />
          {/each}
        </dl>

        <dl class="mt-10 rounded-2xl border border-gray-200 p-4 space-y-4">
          <div>
            <dt class="text-sm text-gray-700 mb-1">
              {$_("report_page.options.tenure")}
            </dt>
            <dd class="font-medium">
              {$finalCalculation.tenure}
              {$_("common.year")}
            </dd>
          </div>
          <hr class="border-gray-200" />
          <div>
            <dt class="text-sm text-gray-700 mb-1">
              {$_("report_page.options.amountWithBankProfit")}
            </dt>
            <dd class="font-medium">
              {prettyNumbers($finalCalculation.amount_with_bank_profit)}
              {$_("common.sar")}
              <span class="mx-2 opacity-25">/</span>
              <span class="font-light">
                {prettyNumbers(
                  $finalCalculation.bank_profit_percentage_over_amount
                )}%
                {$_("report_page.options.bankProfitOverAmount")}
              </span>
            </dd>
            <dd class="font-medium" />
          </div>
          <hr class="border-gray-200" />
          <div>
            <dt class="text-sm text-gray-700 mb-1">
              {$_("report_page.options.downpayment")}
            </dt>
            <dd class="font-medium">
              {prettyNumbers($finalCalculation.down_payment)}
              {$_("common.sar")}<span class="mx-2 opacity-25">/</span>
              <span class="font-light">
                10%
                {$_("report_page.options.bankProfitOverAmount")}
              </span>
            </dd>
          </div>
          <hr class="border-gray-200" />
          <div>
            <dt class="text-sm text-gray-700 mb-1">
              {$_("report_page.options.apr")}
            </dt>
            <dd class="font-medium">
              %{$finalCalculation.profit_rate_percentage}
            </dd>
          </div>
          <hr class="border-gray-200" />

          <div>
            <dt class="text-sm text-gray-700 mb-1">
              {$_("report_page.options.adminfees")}
            </dt>
            <dd class="font-medium">
              {$finalCalculation.admin_fees}
              {$_("common.sar")}
            </dd>
          </div>
        </dl>
      </main>
    {:else}
      <div
        class="flex flex-col px-8 mt-32 text-center h-full items-center justify-center"
      >
        <BeachLogo />
        <h2 class="font-bold mt-4">{$_("report_page.tips.title")}</h2>
        <p class="text-gray-700">{$_("report_page.tips.subtitle")}</p>
      </div>
    {/if}
  {:else}
    <div>error</div>
  {/if}
</div>
