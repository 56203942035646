<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  {...$$props}
>
  <path
    d="M16.192 6.34399L11.949 10.586L7.70703 6.34399L6.29303 7.75799L10.535 12L6.29303 16.242L7.70703 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
    fill="currentColor"
  />
</svg>
