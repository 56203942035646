<script>
  import { _ } from "svelte-i18n";
  import LogoBig from "../assets/logo_big.svelte";
  import StepsPaginator from "../components/steps_paginator.svelte";
</script>

<div class="flex flex-col h-screen items-center justify-center text-center">
  <LogoBig />

  <h1 class="text-3xl font-bold mt-6 px-4">{$_("landing_page.title")}</h1>
  <p class="mt-4 px-4">{$_("landing_page.subtitle")}</p>

  <StepsPaginator
    center={true}
    divider={false}
    hidePrev={true}
    nextButtonLabel={$_("landing_page.nextButtonLabel")}
  />
</div>
