<script>
  import { _ } from "svelte-i18n";
  import { reportStore } from "../store";

  const steps = [
    $_("report_nav.details"),
    $_("report_nav.options"),
    $_("report_nav.tips"),
  ];
</script>

<nav class="flex flex-row">
  {#each steps as step, index}
    <button
      class={`text-sm font-semibold h-20 flex items-center justify-center border-b-2 flex-1 ${
        index === $reportStore.tab
          ? "text-green-700 border-b-green-700"
          : "text-gray-500"
      }`}
      on:click={() => {
        reportStore.setTab(index);
      }}
    >
      {step}
    </button>
  {/each}
</nav>
