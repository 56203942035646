<script>
  import { modal } from "../store";
</script>

<div
  class:hidden={!$modal}
  on:click={modal.toggle}
  class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
>
  <slot />
</div>
