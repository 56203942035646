<svg
  class="me-1 accent"
  width="42"
  height="26"
  viewBox="0 0 42 26"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect x="0.5" y="0.5" width="41" height="25" rx="4.5" stroke="#EEEEEE" />
  <g clip-path="url(#clip0_175_2338)">
    <path d="M18.5363 14.1532H5V18.6626H18.5363V14.1532Z" fill="#84B740" />
    <path d="M18.5363 8H5V12.5134H18.5363V8Z" fill="#259BD6" />
    <path
      d="M32.0727 17.7463L32.0124 17.7584C31.8034 17.7986 31.727 17.8147 31.5743 17.8147C31.2206 17.8147 30.8026 17.6338 30.8026 16.7818C30.8026 16.3437 30.875 15.7609 31.5341 15.7609H31.5381C31.6507 15.7689 31.7793 15.781 32.0204 15.8533L32.0727 15.8694V17.7463ZM32.1812 13.4982L32.0727 13.5183V15.0937L31.9762 15.0656L31.9481 15.0576C31.8396 15.0254 31.5904 14.9531 31.3492 14.9531C30.031 14.9531 29.7537 15.9498 29.7537 16.7858C29.7537 17.9312 30.3967 18.5903 31.518 18.5903C31.9923 18.5903 32.342 18.5421 32.6956 18.4256C33.0212 18.3211 33.1377 18.1724 33.1377 17.8549V13.3334C32.8242 13.3896 32.4987 13.4459 32.1812 13.4982Z"
      fill="#27292D"
    />
    <path
      d="M35.9912 17.7745L35.935 17.7906L35.734 17.8428C35.5451 17.891 35.3763 17.9192 35.2477 17.9192C34.9382 17.9192 34.7534 17.7664 34.7534 17.5052C34.7534 17.3364 34.8297 17.051 35.3361 17.051H35.9912V17.7745ZM35.529 14.933C35.1231 14.933 34.7051 15.0053 34.1867 15.1661L33.8491 15.2666L33.9616 16.0302L34.2912 15.9217C34.6368 15.8091 35.0669 15.7368 35.3884 15.7368C35.5331 15.7368 35.9752 15.7368 35.9752 16.2151V16.4241H35.3602C34.2389 16.4241 33.7205 16.7818 33.7205 17.5494C33.7205 18.2045 34.1987 18.5984 35.0025 18.5984C35.2517 18.5984 35.5974 18.5502 35.8948 18.4778L35.9109 18.4738L35.9269 18.4778L36.0274 18.4939C36.3409 18.5502 36.6665 18.6064 36.984 18.6667V16.1588C36.984 15.3469 36.4936 14.933 35.529 14.933Z"
      fill="#27292D"
    />
    <path
      d="M28.1822 17.7745L28.1259 17.7906L27.9249 17.8428C27.736 17.891 27.5713 17.9192 27.4386 17.9192C27.1292 17.9192 26.9443 17.7664 26.9443 17.5052C26.9443 17.3364 27.0206 17.051 27.523 17.051H28.1781L28.1822 17.7745ZM27.724 14.933C27.314 14.933 26.9001 15.0053 26.3816 15.1661L26.044 15.2666L26.1565 16.0302L26.4861 15.9217C26.8317 15.8091 27.2618 15.7368 27.5833 15.7368C27.728 15.7368 28.1701 15.7368 28.1701 16.2151V16.4241H27.5552C26.4339 16.4241 25.9114 16.7818 25.9114 17.5494C25.9114 18.2045 26.3896 18.5984 27.1975 18.5984C27.4467 18.5984 27.7923 18.5502 28.0897 18.4778L28.1058 18.4738L28.1219 18.4778L28.2183 18.4939C28.5358 18.5502 28.8574 18.6064 29.1749 18.6707V16.1628C29.1789 15.3429 28.6886 14.933 27.724 14.933Z"
      fill="#27292D"
    />
    <path
      d="M23.9501 14.9409C23.4397 14.9409 23.0177 15.1097 22.8609 15.1821L22.8207 15.2021L22.7845 15.174C22.5675 15.0173 22.25 14.9369 21.8079 14.9369C21.4181 14.9369 21.0523 14.9932 20.6544 15.1097C20.3128 15.2142 20.1802 15.379 20.1802 15.6885V18.5541H21.2493V15.9055L21.3015 15.8894C21.5185 15.8171 21.6471 15.805 21.7717 15.805C22.0812 15.805 22.2379 15.9698 22.2379 16.2913V18.5581H23.291V16.2471C23.291 16.1105 23.2628 16.0301 23.2588 16.014L23.2226 15.9457L23.295 15.9135C23.4557 15.8412 23.6326 15.805 23.8175 15.805C24.0305 15.805 24.2837 15.8894 24.2837 16.2913V18.5581H25.3326V16.1868C25.3326 15.3468 24.8825 14.9409 23.9501 14.9409Z"
      fill="#27292D"
    />
    <path
      d="M35.2035 10.942C35.0467 10.942 34.7855 10.9259 34.5805 10.8857L34.5202 10.8736V9.3263C34.5202 9.19769 34.4961 9.11731 34.4921 9.10525L34.46 9.04094L34.5283 9.01281C34.5444 9.00477 34.5604 9.00075 34.5805 8.99272L34.5926 8.98468C34.6167 8.97664 34.6408 8.9686 34.6649 8.96056C34.677 8.95654 34.685 8.95252 34.6931 8.95252C34.9302 8.88822 35.1472 8.89626 35.2437 8.88822H35.2477C35.9028 8.88822 35.9792 9.47099 35.9792 9.90907C35.9752 10.7611 35.5532 10.942 35.2035 10.942ZM35.1995 8C35.1914 8 35.1794 8 35.1713 8C34.5564 8 33.9254 8.1688 33.7003 8.49837C33.5798 8.65913 33.5115 8.86009 33.5074 9.09721V10.6928C33.5074 10.8294 33.4793 10.8817 33.4753 10.8937L33.4391 10.9621H31.4979V9.8528H31.4939C31.4698 8.68325 30.7785 8.04019 29.7777 8.04019H29.6612H28.8011C28.7609 8.32555 28.7287 8.5265 28.6885 8.81186H29.6612C30.1716 8.81186 30.4409 9.24592 30.4409 9.91309V11.0304L30.3725 10.9942C30.3605 10.9902 30.2761 10.9621 30.1435 10.9621H28.4635C28.4313 11.1751 28.3911 11.4524 28.3469 11.7297H33.5115C33.6883 11.6935 33.8933 11.6614 34.0701 11.6333C34.3313 11.7619 34.8177 11.8302 35.1512 11.8302C36.2726 11.8302 37 11.0786 37 9.92113C36.996 8.77568 36.2927 8.02411 35.1995 8Z"
      fill="#27292D"
    />
    <path
      d="M26.1445 12.1999H26.1927C27.3141 12.1999 27.8365 11.8302 27.8365 10.9178C27.8365 10.2627 27.3583 9.74024 26.5545 9.74024H25.5215C25.2121 9.74024 25.0272 9.5634 25.0272 9.26599C25.0272 9.06504 25.1036 8.81585 25.61 8.81585H27.8687C27.9169 8.52246 27.941 8.33758 27.9852 8.04419H25.6381C24.5449 8.04419 23.9943 8.50237 23.9943 9.26599C23.9943 10.0216 24.4726 10.4155 25.2764 10.4155H26.3093C26.6188 10.4155 26.8036 10.6606 26.8036 10.9178C26.8036 11.0866 26.7273 11.4363 26.2249 11.4363H26.0521L22.7444 11.4283H22.1415C21.6311 11.4283 21.2734 11.1389 21.2734 10.4677V10.0055C21.2734 9.30618 21.5507 8.87212 22.1415 8.87212H23.1221C23.1664 8.57471 23.1945 8.38581 23.2347 8.10448H22.0129H21.8963C20.8956 8.10448 20.2043 8.77566 20.1802 9.94522V9.98943V10.4677C20.2043 11.6373 20.8956 12.1959 21.8963 12.1959H22.0129H22.873L24.6655 12.1999H25.7346H26.1445Z"
      fill="#27292D"
    />
  </g>
  <defs>
    <clipPath id="clip0_175_2338">
      <rect
        width="32"
        height="10.6707"
        fill="white"
        transform="translate(5 8)"
      />
    </clipPath>
  </defs>
</svg>
