<svg
  class="me-1"
  width="10"
  height="8"
  viewBox="0 0 10 8"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8.98986 2.46519C9.09466 2.35334 9.1764 2.22195 9.23042 2.07851C9.28444 1.93507 9.30968 1.7824 9.3047 1.62921C9.29972 1.47602 9.26461 1.3253 9.20138 1.18568C9.13815 1.04606 9.04804 0.920255 8.93619 0.815455C8.82434 0.710656 8.69295 0.628912 8.54951 0.574892C8.40607 0.520872 8.2534 0.495634 8.10021 0.500617C7.94702 0.505601 7.79631 0.54071 7.65668 0.603938C7.51706 0.667167 7.39125 0.757276 7.28646 0.869124L3.68947 4.70762L1.91256 3.12905C1.67975 2.93552 1.38083 2.84005 1.07894 2.86283C0.777051 2.88561 0.495836 3.02484 0.294702 3.25111C0.0935674 3.47739 -0.0117343 3.77299 0.00104007 4.07547C0.0138145 4.37795 0.143665 4.66361 0.363162 4.87213L2.98827 7.20556C3.21542 7.40733 3.51231 7.51253 3.81581 7.49881C4.11932 7.48508 4.4055 7.3535 4.61351 7.13206L8.98986 2.46519Z"
    fill="currentColor"
  />
</svg>
