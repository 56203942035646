<svg
  class="me-1"
  width="42"
  height="26"
  viewBox="0 0 42 26"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect x="0.5" y="0.5" width="41" height="25" rx="4.5" stroke="#EEEEEE" />
  <g clip-path="url(#clip0_175_2350)">
    <path
      d="M10.0335 15.9135H8.53174L9.47108 10.1053H10.9728L10.0335 15.9135Z"
      fill="#00579F"
    />
    <path
      d="M15.4779 10.2473C15.1817 10.1298 14.7119 10 14.1309 10C12.6478 10 11.6035 10.7909 11.597 11.9215C11.5847 12.7558 12.3448 13.2191 12.9133 13.4973C13.4943 13.7815 13.6918 13.967 13.6918 14.2204C13.6859 14.6095 13.2223 14.7888 12.7899 14.7888C12.1903 14.7888 11.869 14.6964 11.3808 14.4799L11.1831 14.3871L10.9729 15.6909C11.3251 15.8514 11.9741 15.9938 12.6478 16C14.2236 16 15.2495 15.2214 15.2617 14.0165C15.2677 13.3553 14.8663 12.8486 14.0011 12.4347C13.4758 12.1689 13.1541 11.9897 13.1541 11.7178C13.1603 11.4706 13.4262 11.2174 14.0191 11.2174C14.5074 11.205 14.8661 11.3224 15.1378 11.4398L15.2737 11.5015L15.4779 10.2473Z"
      fill="#00579F"
    />
    <path
      d="M17.4739 13.8559C17.5976 13.5222 18.0735 12.2308 18.0735 12.2308C18.0673 12.2432 18.1969 11.891 18.2711 11.6747L18.3761 12.1752C18.3761 12.1752 18.6605 13.5655 18.7222 13.8559C18.4875 13.8559 17.7706 13.8559 17.4739 13.8559ZM19.3277 10.1053H18.1661C17.8078 10.1053 17.5357 10.2103 17.3811 10.5873L15.1504 15.9134H16.7262C16.7262 15.9134 16.9856 15.1966 17.0414 15.0422C17.2143 15.0422 18.7472 15.0422 18.9696 15.0422C19.0127 15.2461 19.1488 15.9134 19.1488 15.9134H20.5393L19.3277 10.1053Z"
      fill="#00579F"
    />
    <path
      d="M7.27741 10.1053L5.80665 14.066L5.64593 13.2627C5.37401 12.3358 4.52124 11.3288 3.56958 10.8281L4.91675 15.9074H6.50487L8.86545 10.1053H7.27741Z"
      fill="#00579F"
    />
    <path
      d="M4.44098 10.1053H2.02472L2 10.2227C3.88483 10.7047 5.13312 11.8665 5.64599 13.2629L5.12072 10.5936C5.03425 10.2226 4.76849 10.1176 4.44098 10.1053Z"
      fill="#FAA61A"
    />
  </g>
  <g clip-path="url(#clip1_175_2350)">
    <path
      d="M32.8171 9.06946H28.4421V16.9306H32.8171V9.06946Z"
      fill="#FF5F00"
    />
    <path
      d="M28.7199 13C28.7199 11.4028 29.4699 9.98612 30.6226 9.06945C29.7754 8.40278 28.706 8 27.5393 8C24.7754 8 22.5393 10.2361 22.5393 13C22.5393 15.7639 24.7754 18 27.5393 18C28.706 18 29.7754 17.5973 30.6226 16.9306C29.4699 16.0278 28.7199 14.5972 28.7199 13Z"
      fill="#EB001B"
    />
    <path
      d="M38.7198 13C38.7198 15.7639 36.4837 18 33.7198 18C32.5531 18 31.4837 17.5973 30.6365 16.9306C31.8031 16.0139 32.5392 14.5972 32.5392 13C32.5392 11.4028 31.7892 9.98612 30.6365 9.06945C31.4837 8.40278 32.5531 8 33.7198 8C36.4837 8 38.7198 10.25 38.7198 13Z"
      fill="#F79E1B"
    />
  </g>
  <defs>
    <clipPath id="clip0_175_2350">
      <rect
        width="18.5392"
        height="6"
        fill="white"
        transform="translate(2 10)"
      />
    </clipPath>
    <clipPath id="clip1_175_2350">
      <rect
        width="16.1806"
        height="10"
        fill="white"
        transform="translate(22.5393 8)"
      />
    </clipPath>
  </defs>
</svg>
