<script>
  import { _ } from "svelte-i18n";
  import { object, number } from "yup";
  import { form, app } from "../store";
  import PlusSign from "../assets/plus_sign.svelte";
  import StepsNav from "../components/steps_nav.svelte";
  import StepsPaginator from "../components/steps_paginator.svelte";
  import CloseIcon from "../assets/close_icon.svelte";

  import {
    convertToEnglishNumbers,
    prettyNumbers,
    integerFromFormattedStringOrNull,
  } from "../../lib/utils";
  import { formatValueWithState } from "../../lib/svelte_actions";

  export let nextButtonLabel;

  function addExistingFinance() {
    form.update((state) => ({
      ...state,
      existing_finances: [
        ...state.existing_finances,
        {
          id: state.existing_finances.length + 1,
          monthly_installment: null,
          remaining_amount: null,
        },
      ],
    }));
  }

  function addCreditCardLimit() {
    form.update((state) => ({
      ...state,
      existing_credit_card_limits: [
        ...state.existing_credit_card_limits,
        {
          id: state.existing_credit_card_limits.length + 1,
          amount: null,
        },
      ],
    }));
  }

  const removeFinance = (targetId) => {
    form.update((state) => ({
      ...state,
      existing_finances: state.existing_finances.filter(
        ({ id }) => targetId !== id
      ),
    }));
  };

  const removeCreditCardLimit = (targetId) => {
    form.update((state) => ({
      ...state,
      existing_credit_card_limits: state.existing_credit_card_limits.filter(
        ({ id }) => targetId !== id
      ),
    }));
  };

  const existingFinanceSchema = object({
    monthly_installment: number().nullable().min(1),
    remaining_amount: number()
      .nullable()
      .test((value, ctx) => {
        if (value >= ctx.parent.monthly_installment) {
          return true;
        }
        return false;
      }),
  });

  const existingCreditCardLimitSchema = object({
    amount: number().nullable().min(1),
  });

  $: disableNext =
    !$form.existing_finances.every((existingFinance) =>
      existingFinanceSchema.isValidSync(existingFinance)
    ) ||
    !$form.existing_credit_card_limits.every((existingCreditCardLimit) =>
      existingCreditCardLimitSchema.isValidSync(existingCreditCardLimit)
    );
</script>

<StepsNav activeStepIndex={1} />
<div class="pt-14 px-10 pb-40">
  <h1 class="font-bold text-3xl text-center mb-6">
    {$_("obligations_page.title")}
  </h1>
  <div>
    {#each $form.existing_finances as existingFinance (existingFinance.id)}
      <div class="flex flex-col mb-2 relative">
        <button
          class="absolute top-0 end-0 mt-4 -me-4 bg-white rounded-full w-8 h-8 flex justify-center items-center shadow-md"
          on:click|preventDefault={() => removeFinance(existingFinance.id)}
        >
          <CloseIcon class="text-red-500" />
        </button>
        <div class="flex flex-row text-sm">
          <label
            class="font-light flex-1 py-3 px-4 rounded-s-xl bg-gray-100 border-2 border-gray-100 focus-within:bg-white focus-within:border-green-700 me-1"
          >
            {$_("obligations_page.form.label.amount")}
            <input
              class="w-full placeholder-gray-900 placeholder-opacity-20 text-lg focus:outline-none bg-transparent {$app.dir ===
              'rtl'
                ? 'text-right'
                : 'text-left'}"
              dir="ltr"
              inputmode="numeric"
              use:formatValueWithState={{
                stateGetter: () => existingFinance.monthly_installment,
                stateSetter: (val) =>
                  (existingFinance.monthly_installment = val),
                formatter: prettyNumbers,
                parser: (val) =>
                  integerFromFormattedStringOrNull(
                    convertToEnglishNumbers(val)
                  ),
              }}
              placeholder={0}
            />
          </label>
          <label
            class="font-light flex-1 py-3 px-4 rounded-e-xl bg-gray-100 border-2 border-gray-100 focus-within:bg-white focus-within:border-green-700"
          >
            {$_("obligations_page.form.label.remaining")}
            <input
              class="w-full placeholder-gray-900 placeholder-opacity-20 text-lg focus:outline-none bg-transparent {$app.dir ===
              'rtl'
                ? 'text-right'
                : 'text-left'}"
              dir="ltr"
              inputmode="numeric"
              use:formatValueWithState={{
                stateGetter: () => existingFinance.remaining_amount,
                stateSetter: (val) => (existingFinance.remaining_amount = val),
                formatter: prettyNumbers,
                parser: (val) =>
                  integerFromFormattedStringOrNull(
                    convertToEnglishNumbers(val)
                  ),
              }}
              placeholder={0}
            />
          </label>
        </div>
      </div>
    {/each}
  </div>
  <button
    class="mb-8 bg-gray-100 rounded-lg w-full py-3 px-4 flex justify-between"
    on:click={addExistingFinance}
    >{$_("obligations_page.form.button.addObligation")}
    <PlusSign />
  </button>

  <div>
    {#each $form.existing_credit_card_limits as existingCreditCardLimit (existingCreditCardLimit.id)}
      <div
        class="bg-gray-100 border-2 border-gray-100 focus-within:bg-white focus-within:border-green-700 rounded-xl flex flex-col mb-2"
      >
        <label class="flex flex-col font-light text-sm py-3 px-4 relative">
          {$_("obligations_page.form.label.creditCardLimit")}
          <!-- TODO: Figure out why formatValueWithState doesn't work with arrays -->
          <input
            dir="ltr"
            inputmode="numeric"
            class="w-full placeholder-gray-900 placeholder-opacity-20 text-lg focus:outline-none bg-transparent {$app.dir ===
            'rtl'
              ? 'text-right'
              : 'text-left'}"
            use:formatValueWithState={{
              stateGetter: () => existingCreditCardLimit.amount,
              stateSetter: (val) => (existingCreditCardLimit.amount = val),
              formatter: prettyNumbers,
              parser: (val) =>
                integerFromFormattedStringOrNull(convertToEnglishNumbers(val)),
            }}
            placeholder={0}
          />
          <button
            class="absolute top-0 end-0 mt-4 -me-4 bg-white rounded-full w-8 h-8 flex justify-center items-center shadow-md"
            on:click|preventDefault={() =>
              removeCreditCardLimit(existingCreditCardLimit.id)}
          >
            <CloseIcon class="text-red-500" />
          </button>
        </label>
      </div>
    {/each}
  </div>

  <button
    class="bg-gray-100 rounded-lg w-full py-3 px-4 flex justify-between"
    on:click={addCreditCardLimit}
    >{$_("obligations_page.form.button.addCreditCard")}
    <PlusSign />
  </button>
</div>

<StepsPaginator {disableNext} {nextButtonLabel} />
