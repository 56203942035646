import App from "../finance_calculators/real_estate";
import axios from "axios";

axios.defaults.headers.common["X-CSRF-Token"] = document.querySelector(
  'meta[name="csrf-token"]'
).content;

document.addEventListener(
  "DOMContentLoaded",
  () => new App({ target: document.body })
);
