<script>
  import { app } from "../store";
  import { _ } from "svelte-i18n";
  import confetti from "canvas-confetti";
  import Party from "../assets/party.svelte";
  import { encodeUserState } from "../../lib/utils";

  setTimeout(
    () =>
      confetti({
        particleCount: 100,
        angle: 90,
        spread: 360,
        origin: {
          x: 0.5,
          y: 0.1,
        },
      }),
    500
  );

  function shareReportLink() {
    const userState = encodeUserState({
      app: { ...$app, step: 7 },
    });
    const url = `${window.location.origin}/?user_state=${userState}`;

    if (navigator.share) {
      navigator.share({
        text: $_("success_page.share_title"),
        url,
      });
    } else {
      navigator.clipboard
        .writeText(url)
        .then(() => alert($_("success_page.share_succeed")));
    }
  }
</script>

<div
  class="flex flex-col items-center justify-center h-screen text-center px-6"
>
  <Party />
  <h2 class="font-bold mt-4">{$_("success_page.title")}</h2>
  <p class="text-gray-700">{$_("success_page.subtitle")}</p>
  <button
    class="bg-green-700 text-white mt-6 mb-2 py-4 w-60 xs:w-80 rounded-2xl disabled:opacity-50 disabled:pointer-events-none flex justify-center items-center"
    on:click={app.nextStep}
  >
    {$_("success_page.view_report")}
  </button>
  <button
    class="bg-green-100 text-green-700 py-4 w-60 xs:w-80 rounded-2xl disabled:opacity-50 disabled:pointer-events-none flex justify-center items-center"
    on:click={shareReportLink}
  >
    {$_("success_page.copy_report_link")}
  </button>
</div>
