<svg
  width="88"
  height="88"
  viewBox="0 0 88 88"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M12.1756 77.9419C9.50813 76.3675 5.95376 73.4387 6.05688 68.5162C6.14626 64.3362 12.0588 57.6881 27.2181 55.1443C42.3775 52.6006 50.7444 53.8106 57.5369 54.5393C64.3294 55.2681 68.5713 57.4475 68.5713 57.4475L62.3838 69.9394L12.1756 77.9419Z"
    fill="#04C1EA"
  />
  <path
    d="M39.0636 66.5226L25.128 70.2213L13.963 75.1507C13.963 75.1507 12.5743 78.0794 13.2686 78.8494C13.963 79.6194 24.2068 85.4769 42.3705 85.5457C62.473 85.6213 73.1637 80.3894 76.8624 77.6944C80.5612 74.9994 69.5474 59.5238 69.5474 59.5238L60.3555 55.7426L49.9124 56.7532L43.7524 59.8332L39.0636 66.5226Z"
    fill="#F5C876"
  />
  <path
    d="M48.1319 65.2644L63.7038 64.4394C63.7038 64.4394 61.6688 60.5344 60.9194 58.7813C60.0669 56.7875 59.9088 55.6394 59.9088 55.6394C59.9088 55.6394 61.7375 55.1306 64.8588 55.9213C67.98 56.7119 79.0763 60.2731 81.7919 67.1344C84.1569 73.1156 76.8144 77.7425 76.8144 77.7425C76.8144 77.7425 71.8644 74.3669 70.015 72.6C67.1206 69.8362 65.5531 67.5194 65.5531 67.5194L47.9669 68.1381L46.9219 67.4437L48.1319 65.2644Z"
    fill="#F2A159"
  />
  <path
    d="M14.6642 67.7532C12.6979 69.7194 14.9736 72.1394 14.9736 72.1394C14.9736 72.1394 10.5117 73.1432 10.6629 75.7557C10.7386 77.0619 12.5123 78.6019 13.2823 78.8357C14.0523 79.0694 13.9011 77.5707 14.9736 76.5257C17.7442 73.8307 26.2898 73.2119 32.2161 72.6C38.1423 71.9882 40.5761 69.9394 41.6829 69.2107C44.6117 67.2857 43.5323 61.8338 46.7223 59.4069C51.8511 55.5019 63.5798 56.0382 63.4973 55.605C63.4148 55.1719 53.7761 53.0063 44.9417 56.5813C39.0361 58.9738 39.1461 63.9719 34.9936 65.3607C30.8411 66.7494 17.8198 64.5975 14.6642 67.7532Z"
    fill="#DCECF6"
  />
  <path
    d="M22.9558 69.6093C21.7115 70.0631 22.8871 71.3762 23.4646 71.7062C24.6196 72.3662 26.1802 73.0606 26.1802 73.0606C26.1802 73.0606 22.9008 75.5494 23.8083 76.8487C24.4752 77.8044 30.649 75.4325 30.649 75.4325C30.7108 75.4325 34.4577 78.4919 36.0527 78.4987C37.2421 78.5056 36.4377 74.0644 36.4377 74.0644C36.4377 74.0644 41.4496 72.9987 41.4496 71.4725C41.4496 70.2831 38.9608 70.6268 38.9608 70.6268C38.9608 70.6268 24.2002 69.1556 22.9558 69.6093Z"
    fill="#EE594A"
  />
  <path
    d="M33.5775 66.5569C32.2781 66.4401 29.8856 69.1282 29.8856 69.1282C29.8856 69.1282 22.8387 68.6469 22.7218 69.8363C22.605 71.0257 26.455 71.5344 26.455 72.2082C26.455 72.8819 23.3475 75.3707 23.6293 76.1613C23.9112 76.9519 30.0644 73.3907 30.525 73.5626C30.9856 73.7344 35.6469 78.2376 36.4787 77.4676C37.1937 76.8076 36.4581 72.9369 36.4581 72.9369C36.4581 72.9369 41.2087 71.8094 41.1469 70.9569C41.0919 70.1113 35.1587 69.4857 35.1587 69.4857C35.1587 69.4857 34.8219 66.6669 33.5775 66.5569Z"
    fill="#F79228"
  />
  <path
    d="M42.3569 12.2581L39.105 12.0244L42.4187 36.2656C42.4187 36.2656 45.7394 63.58 45.9662 65.2162C46.255 67.3062 46.7706 68.42 48.6131 68.1381C50.4556 67.8562 50.27 66.0481 50.1531 64.6387C50.0362 63.2293 47.0869 37.5993 47.0869 37.5993L42.3569 12.2581Z"
    fill="#4EA4B0"
  />
  <path
    d="M39.6964 8.13999C39.6964 8.13999 24.0008 9.85874 13.8945 21.0787C5.74077 30.1331 4.41389 38.2662 4.99139 38.3556C5.96077 38.5069 8.42889 37.6819 11.1239 38.8025C13.8189 39.9231 15.3864 42.24 16.287 42.24C17.1876 42.24 20.4739 39.9987 25.1145 40.6725C29.7483 41.3462 32.8901 44.11 33.8664 44.11C34.8426 44.11 38.727 41.195 44.7083 40.8925C50.167 40.6175 53.8314 41.9375 54.5808 41.8619C55.3301 41.7862 56.7876 37.1594 62.5489 35.4406C68.3101 33.7219 70.2145 35.1312 71.0395 35.1312C71.8645 35.1312 72.3114 31.0956 76.4226 29.5212C80.5339 27.9537 82.1083 28.6206 83.002 28.4762C83.8958 28.3319 79.7089 18.315 64.8658 11.5294C52.5939 5.92624 39.6964 8.13999 39.6964 8.13999Z"
    fill="#EE5948"
  />
  <path
    d="M40.4181 9.27435C40.4181 9.27435 30.2019 12.3337 23.0931 23.4781C17.2769 32.5944 17.1875 39.3319 17.5587 39.7031C17.93 40.0744 21.3744 38.3556 25.5612 38.7337C29.7481 39.105 33.2819 42.2194 33.4881 41.5044C33.7494 40.5969 33.6669 32.5531 34.6156 25.7812C36.3825 13.1106 40.5075 10.3537 40.5075 10.3537C40.5075 10.3537 46.0075 14.0387 50.1806 24.4406C52.8 30.965 54.0994 37.6681 54.6425 38.5206C54.8831 38.8987 57.145 35.4337 61.8475 33.9075C66.1994 32.4912 70.0012 33.5844 70.5237 33.2887C71.0462 32.9862 66.165 22.3987 58.0181 16.3075C48.8194 9.41185 40.4181 9.27435 40.4181 9.27435Z"
    fill="#FEFEFE"
  />
  <path
    d="M39.4902 2.68815C37.4827 2.96315 38.1221 5.89878 38.2733 7.6244C38.3421 8.38753 38.5071 11.3163 40.5215 11.1582C42.199 11.0275 41.9652 8.79315 41.814 7.15003C41.6696 5.50003 41.6008 2.3994 39.4902 2.68815Z"
    fill="#4EA4B0"
  />
</svg>
