<svg
  width="76"
  height="76"
  viewBox="0 0 76 76"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M37.7627 70.5375C21.1971 70.5375 3.3252 60.1469 3.3252 37.3469C3.3252 14.5469 21.1971 4.15625 37.7627 4.15625C46.9658 4.15625 55.4564 7.18437 61.7502 12.7062C68.5783 18.7625 72.2002 27.3125 72.2002 37.3469C72.2002 47.3812 68.5783 55.8719 61.7502 61.9281C55.4564 67.45 46.9064 70.5375 37.7627 70.5375Z"
    fill="url(#paint0_radial_293_1320)"
  />
  <path
    d="M66.1973 17.6166C69.362 22.7228 71.0126 28.8028 71.0126 35.5656C71.0126 45.6 67.3908 54.0906 60.5626 60.1469C54.2689 65.6688 45.7189 68.7563 36.5751 68.7563C25.852 68.7563 14.6064 64.3922 7.96826 55.1356C14.357 65.6213 26.3745 70.5375 37.7626 70.5375C46.9064 70.5375 55.4564 67.45 61.7501 61.9281C68.5783 55.8719 72.2001 47.3813 72.2001 37.3469C72.2001 29.7825 70.1398 23.0613 66.1973 17.6166Z"
    fill="#EB8F00"
  />
  <path
    d="M42.1559 59.9687C41.8175 59.9687 41.479 59.8975 41.1703 59.755C40.0908 59.2751 38.9235 59.0241 37.7421 59.018C36.5608 59.0119 35.391 59.2507 34.3065 59.7194C33.1368 60.3072 31.7059 59.8322 31.1181 58.6625C30.8363 58.0994 30.7894 57.4475 30.9875 56.8498C31.1856 56.2521 31.6126 55.7574 32.175 55.474C33.8966 54.694 35.7637 54.287 37.6538 54.2799C39.5439 54.2727 41.4141 54.6655 43.1415 55.4325C43.4254 55.5619 43.681 55.7459 43.8937 55.9741C44.1064 56.2023 44.2721 56.4702 44.3812 56.7624C44.4904 57.0546 44.5409 57.3655 44.5299 57.6773C44.5189 57.989 44.4466 58.2956 44.3172 58.5794C44.1287 58.9943 43.8246 59.3461 43.4412 59.5925C43.0579 59.8389 42.6116 59.9696 42.1559 59.9687Z"
    fill="#422B0D"
  />
  <path
    d="M25.531 28.3219C31.2191 28.3397 35.8207 32.965 35.8029 38.6531C35.7851 44.3413 31.1598 48.9428 25.4716 48.925C19.7835 48.9072 15.1819 44.2819 15.1998 38.5938C15.2354 32.9116 19.8488 28.3278 25.531 28.3219Z"
    fill="white"
  />
  <path
    d="M25.3527 46.4313C30.4027 46.4313 34.4965 42.3375 34.4965 37.2875C34.4965 32.2376 30.4027 28.1438 25.3527 28.1438C20.3028 28.1438 16.209 32.2376 16.209 37.2875C16.209 42.3375 20.3028 46.4313 25.3527 46.4313Z"
    fill="#422B0D"
  />
  <path
    d="M29.4908 41.8366C30.1438 41.2885 30.263 40.3555 29.7571 39.7527C29.2511 39.1499 28.3115 39.1056 27.6585 39.6537C27.0055 40.2019 26.8863 41.1349 27.3922 41.7377C27.8982 42.3405 28.8378 42.3848 29.4908 41.8366Z"
    fill="white"
  />
  <path
    d="M25.5607 37.9209C27.9282 36.1587 28.6887 33.1735 27.2594 31.2533C25.8301 29.333 22.7523 29.2048 20.3848 30.967C18.0173 32.7291 17.2568 35.7143 18.6861 37.6346C20.1154 39.5548 23.1933 39.683 25.5607 37.9209Z"
    fill="white"
  />
  <path
    d="M51.0623 28.3219C56.7504 28.3397 61.3519 32.965 61.3341 38.6531C61.3163 44.3413 56.691 48.9428 51.0029 48.925C45.3148 48.9072 40.7132 44.2819 40.731 38.5938C40.7666 32.9116 45.3801 28.3278 51.0623 28.3219Z"
    fill="white"
  />
  <path
    d="M50.884 46.4313C55.9339 46.4313 60.0277 42.3375 60.0277 37.2875C60.0277 32.2376 55.9339 28.1438 50.884 28.1438C45.834 28.1438 41.7402 32.2376 41.7402 37.2875C41.7402 42.3375 45.834 46.4313 50.884 46.4313Z"
    fill="#422B0D"
  />
  <path
    d="M55.0704 41.8003C55.7234 41.2521 55.8426 40.3191 55.3366 39.7163C54.8307 39.1135 53.8911 39.0692 53.2381 39.6174C52.585 40.1655 52.4658 41.0985 52.9718 41.7013C53.4778 42.3041 54.4174 42.3484 55.0704 41.8003Z"
    fill="white"
  />
  <path
    d="M51.0983 37.9248C53.4658 36.1626 54.2263 33.1774 52.797 31.2572C51.3677 29.3369 48.2899 29.2087 45.9224 30.9709C43.5549 32.733 42.7944 35.7182 44.2237 37.6385C45.653 39.5587 48.7309 39.6869 51.0983 37.9248Z"
    fill="white"
  />
  <path
    d="M16.2685 23.6312C14.9622 23.8687 14.9028 25.7687 16.3278 25.8281C19.4747 25.8697 22.5147 24.7 24.8185 22.5625C25.6972 21.8025 26.4394 20.9 27.0153 19.8906C27.3597 19.3978 27.241 18.7209 26.7481 18.3766C26.6312 18.2946 26.4993 18.2365 26.3599 18.2056C26.2205 18.1747 26.0764 18.1715 25.9357 18.1963C25.7951 18.2212 25.6608 18.2734 25.5404 18.3502C25.42 18.427 25.3159 18.5267 25.2341 18.6437L25.1747 18.7031C22.8476 21.3044 19.7085 23.0413 16.2685 23.6312Z"
    fill="#422B0D"
  />
  <path
    d="M50.1714 18.6437C50.0895 18.5267 49.9855 18.427 49.8651 18.3502C49.7447 18.2734 49.6103 18.2212 49.4697 18.1963C49.3291 18.1715 49.185 18.1747 49.0456 18.2056C48.9062 18.2365 48.7742 18.2946 48.6573 18.3766C48.5403 18.4584 48.4405 18.5625 48.3638 18.6829C48.287 18.8033 48.2347 18.9376 48.2099 19.0782C48.1851 19.2188 48.1882 19.363 48.2191 19.5024C48.2501 19.6418 48.3082 19.7737 48.3901 19.8906C48.966 20.9 49.7082 21.8025 50.587 22.5625C52.9026 24.6822 55.9367 25.8519 59.0776 25.8281C60.562 25.7687 60.4432 23.8687 59.137 23.6312C55.6597 23.0609 52.4896 21.2975 50.1714 18.6437Z"
    fill="#422B0D"
  />
  <defs>
    <radialGradient
      id="paint0_radial_293_1320"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(37.7627 37.3469) scale(33.82)"
    >
      <stop offset="0.5" stop-color="#FDE030" />
      <stop offset="0.92" stop-color="#F7C02B" />
      <stop offset="1" stop-color="#F4A223" />
    </radialGradient>
  </defs>
</svg>
