<svg
  width="40"
  height="40"
  viewBox="0 0 40 40"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M32.4465 14.1168L22.9233 6.71653C22.0876 6.06696 21.0589 5.71429 20.0001 5.71429C18.9412 5.71429 17.9126 6.06696 17.0769 6.71653L7.55186 14.1168C6.97943 14.5616 6.51631 15.1311 6.19785 15.782C5.87939 16.4329 5.71401 17.1478 5.71436 17.8723V30.7176C5.71436 31.6639 6.09063 32.5715 6.7604 33.2406C7.43018 33.9098 8.33858 34.2857 9.28578 34.2857H30.7144C31.6616 34.2857 32.57 33.9098 33.2397 33.2406C33.9095 32.5715 34.2858 31.6639 34.2858 30.7176V17.8723C34.2858 16.404 33.6072 15.0178 32.4465 14.1168Z"
    fill="#007848"
  />
  <path
    d="M25.7142 25C22.5571 26.9048 17.4399 26.9048 14.2856 25"
    stroke="#F9FAFB"
    stroke-width="3.75"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
