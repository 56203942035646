import { addMessages, init } from "svelte-i18n";

import en from "./en.json";
import ar from "./ar.json";

addMessages("en", en);
addMessages("ar", ar);

init({
  fallbackLocale: "ar",
  initialLocale: document.documentElement.lang,
});
