<script>
  import { _ } from "svelte-i18n";
  import { fly } from "svelte/transition";
  import { copyToClipboard, encodeUserState } from "../../lib/utils";
  import { ios, mac, safari } from "is_js";
  import { continueInSafariUserState, app, modal } from "../store";
  import ApplePayLogo from "../assets/apple_pay_logo.svelte";
  import MadaLogo from "../assets/mada_logo.svelte";
  import CreditCardLogo from "../assets/credit_card_logo.svelte";
  import HyperpayForm from "../components/hyperpay_form.svelte";
  import SafariLogo from "../assets/safari_logo.svelte";
  import Modal from "./modal.svelte";
  import OpenInSafariExample from "../assets/open_in_safari_example.svelte";

  let paymentMethod = null;

  function copySafariLink() {
    copyToClipboard(
      `${window.location.origin}?user_state=${encodeUserState(
        $continueInSafariUserState
      )}`
    );
    modal.toggle();
  }
</script>

<div in:fly={{ y: 50, duration: 500 }}>
  {#if ios() || mac()}
    <label class="flex items-center py-3 mt-4">
      <input
        type="radio"
        name="payment-method"
        value="applepay"
        class="peer hidden"
        bind:group={paymentMethod}
        checked={paymentMethod === "applepay"}
      />
      <div
        class="w-5 h-5 bg-white border border-gray-300 peer-checked:border-[6px] peer-checked:border-green-700 rounded-full me-2"
      />
      <ApplePayLogo />
      {$_("summary_page.paymentForm.applyPay")}
    </label>
    {#if paymentMethod === "applepay"}
      {#if window.ApplePaySession}
        <HyperpayForm entityType={paymentMethod} />
      {:else}
        <Modal show={$modal}>
          <div class="relative rounded-3xl bg-white p-6">
            <OpenInSafariExample />
            <div
              class="absolute top-0 start-0 mt-14 ms-6 text-white scale-[0.8]"
            >
              <h3 class="font-bold mb-2">
                {$_("common.openInSafariExampleTitle")}
              </h3>
              <p>{$_("common.openInSafariExampleSubtitle")}</p>
            </div>
            <button
              class="bg-gray-100 text-green-900 py-4 w-full rounded-2xl disabled:opacity-50 disabled:pointer-events-none flex justify-center items-center mt-3"
            >
              {$_("common.ok")}
            </button>
          </div>
        </Modal>
        <div on:click={copySafariLink}>
          <div
            class="flex bg-blue-50 rounded-2xl p-4 items-center justify-center w-full md:w-96"
          >
            <p>{$_("common.openInSafariWarning")}</p>
            <SafariLogo class="w-16 h-16 ms-2" />
          </div>
          <button
            class="bg-gray-100 text-green-900 py-4 w-full md:w-96 rounded-2xl disabled:opacity-50 disabled:pointer-events-none flex justify-center items-center my-3"
          >
            {$_("hyperpay.applepay.copy_pre_payment_link")}
          </button>
        </div>
      {/if}
    {/if}
  {/if}
  <label
    class="flex items-center py-3 border-t first:border-0 border-gray-200 border-opacity-50"
  >
    <input
      type="radio"
      name="payment-method"
      value="mada"
      class="peer hidden"
      bind:group={paymentMethod}
      checked={paymentMethod === "mada"}
    />
    <div
      class="w-5 h-5 bg-white border border-gray-300 peer-checked:border-[6px] peer-checked:border-green-700 rounded-full me-2"
    />
    <MadaLogo />

    {$_("summary_page.paymentForm.mada")}
  </label>
  {#if paymentMethod === "mada"}
    <HyperpayForm entityType={paymentMethod} />
  {/if}
  <label
    class="flex items-center py-3 border-t border-gray-200 border-opacity-50"
  >
    <input
      type="radio"
      name="payment-method"
      value="creditcard"
      class="peer hidden"
      bind:group={paymentMethod}
      checked={paymentMethod === "creditcard"}
    />
    <div
      class="w-5 h-5 bg-white border border-gray-300 peer-checked:border-[6px] peer-checked:border-green-700 rounded-full me-2"
    />
    <CreditCardLogo />

    {$_("summary_page.paymentForm.creditCard")}
  </label>
  {#if paymentMethod === "creditcard"}
    <HyperpayForm entityType={paymentMethod} />
  {/if}
</div>
