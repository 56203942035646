<svg
  width="261"
  height="192"
  viewBox="0 0 261 192"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_399_3328)">
    <rect width="261.468" height="192" fill="#063126" />
    <rect
      x="7.9458"
      y="118.458"
      width="246.576"
      height="64.5961"
      rx="16"
      fill="#F5F5F5"
      fill-opacity="0.3"
    />
    <rect
      x="19.9165"
      y="129.571"
      width="41.6749"
      height="41.6749"
      rx="11.1133"
      fill="#F5F5F5"
      fill-opacity="0.3"
    />
    <rect
      x="80.3452"
      y="129.571"
      width="41.6749"
      height="41.6749"
      rx="11.1133"
      fill="#F5F5F5"
      fill-opacity="0.3"
    />
    <rect
      x="140.773"
      y="129.571"
      width="41.6749"
      height="41.6749"
      rx="11.1133"
      fill="#F5F5F5"
      fill-opacity="0.3"
    />
    <rect
      x="201.04"
      y="129.571"
      width="41.6749"
      height="41.6749"
      rx="9.02956"
      fill="white"
    />
    <rect
      x="200.692"
      y="129.224"
      width="42.3695"
      height="42.3695"
      rx="9.37685"
      stroke="black"
      stroke-opacity="0.07"
      stroke-width="0.694581"
    />
    <circle
      cx="221.877"
      cy="150.409"
      r="18.2328"
      fill="url(#paint0_linear_399_3328)"
    />
    <rect
      x="221.633"
      y="133.478"
      width="0.488377"
      height="3.09306"
      fill="white"
    />
    <rect
      x="221.633"
      y="164.246"
      width="0.488377"
      height="3.09306"
      fill="white"
    />
    <rect
      x="218.696"
      y="133.778"
      width="0.488378"
      height="3.09306"
      transform="rotate(-10 218.696 133.778)"
      fill="white"
    />
    <rect
      width="0.488381"
      height="3.09305"
      transform="matrix(0.984808 -0.173646 0.17365 0.984807 224.039 164.078)"
      fill="white"
    />
    <rect
      x="215.857"
      y="134.583"
      width="0.488379"
      height="3.09306"
      transform="rotate(-20 215.857 134.583)"
      fill="white"
    />
    <rect
      x="226.38"
      y="163.495"
      width="0.488377"
      height="3.09305"
      transform="rotate(-20 226.38 163.495)"
      fill="white"
    />
    <rect
      width="0.488379"
      height="3.09306"
      transform="matrix(0.939693 0.34202 0.34202 -0.939693 215.857 166.235)"
      fill="white"
    />
    <rect
      width="0.488377"
      height="3.09306"
      transform="matrix(0.939692 0.342021 0.34202 -0.939693 226.38 137.323)"
      fill="white"
    />
    <rect
      width="0.488377"
      height="3.09304"
      transform="matrix(0.984808 0.173648 0.173648 -0.984808 218.696 167.04)"
      fill="white"
    />
    <rect
      width="0.488381"
      height="3.09306"
      transform="matrix(0.984808 0.173647 0.17365 -0.984807 224.039 136.739)"
      fill="white"
    />
    <rect
      x="213.2"
      y="135.869"
      width="0.488377"
      height="3.09306"
      transform="rotate(-30 213.2 135.869)"
      fill="white"
    />
    <rect
      width="0.488376"
      height="3.09306"
      transform="matrix(0.866023 -0.500004 0.499997 0.866027 228.584 162.514)"
      fill="white"
    />
    <rect
      width="0.488377"
      height="3.09305"
      transform="matrix(0.866025 0.5 0.5 -0.866025 213.2 164.949)"
      fill="white"
    />
    <rect
      width="0.488376"
      height="3.09305"
      transform="matrix(0.866025 0.500001 0.499999 -0.866026 228.584 138.303)"
      fill="white"
    />
    <rect
      x="210.807"
      y="137.596"
      width="0.488377"
      height="3.09305"
      transform="rotate(-40 210.807 137.596)"
      fill="white"
    />
    <rect
      x="230.584"
      y="161.166"
      width="0.488378"
      height="3.09306"
      transform="rotate(-40 230.584 161.166)"
      fill="white"
    />
    <rect
      width="0.488377"
      height="3.09305"
      transform="matrix(0.766044 0.642788 0.642788 -0.766045 210.807 163.221)"
      fill="white"
    />
    <rect
      width="0.488378"
      height="3.09306"
      transform="matrix(0.766045 0.642787 0.642788 -0.766044 230.584 139.651)"
      fill="white"
    />
    <rect
      x="208.75"
      y="139.713"
      width="0.488378"
      height="3.09306"
      transform="rotate(-50 208.75 139.713)"
      fill="white"
    />
    <rect
      width="0.488377"
      height="3.09306"
      transform="matrix(0.642787 -0.766045 0.766044 0.642788 232.32 159.49)"
      fill="white"
    />
    <rect
      width="0.488376"
      height="3.09305"
      transform="matrix(0.642787 0.766045 0.766044 -0.642788 208.75 161.104)"
      fill="white"
    />
    <rect
      width="0.488377"
      height="3.09306"
      transform="matrix(0.642787 0.766045 0.766044 -0.642788 232.32 141.327)"
      fill="white"
    />
    <rect
      x="207.093"
      y="142.155"
      width="0.488377"
      height="3.09306"
      transform="rotate(-60 207.093 142.155)"
      fill="white"
    />
    <rect
      width="0.488378"
      height="3.09307"
      transform="matrix(0.500002 -0.866024 0.866027 0.499998 233.738 157.539)"
      fill="white"
    />
    <rect
      width="0.488379"
      height="3.09306"
      transform="matrix(0.5 0.866025 0.866025 -0.5 207.093 158.663)"
      fill="white"
    />
    <rect
      width="0.488378"
      height="3.09307"
      transform="matrix(0.500001 0.866025 0.866026 -0.499999 233.738 143.279)"
      fill="white"
    />
    <rect
      x="205.884"
      y="144.848"
      width="0.488379"
      height="3.09306"
      transform="rotate(-70 205.884 144.848)"
      fill="white"
    />
    <rect
      x="234.796"
      y="155.371"
      width="0.488376"
      height="3.09305"
      transform="rotate(-70 234.796 155.371)"
      fill="white"
    />
    <rect
      width="0.488377"
      height="3.09306"
      transform="matrix(0.34202 0.939693 0.939693 -0.34202 205.884 155.97)"
      fill="white"
    />
    <rect
      width="0.488374"
      height="3.09305"
      transform="matrix(0.342022 0.939692 0.939693 -0.342019 234.796 145.447)"
      fill="white"
    />
    <rect
      x="205.161"
      y="147.709"
      width="0.488379"
      height="3.09306"
      transform="rotate(-80 205.161 147.709)"
      fill="white"
    />
    <rect
      width="0.488382"
      height="3.09306"
      transform="matrix(0.173647 -0.984808 0.984807 0.173649 235.461 153.052)"
      fill="white"
    />
    <rect
      width="0.488375"
      height="3.09306"
      transform="matrix(0.173648 0.984808 0.984808 -0.173648 205.161 153.108)"
      fill="white"
    />
    <rect
      width="0.488377"
      height="3.09305"
      transform="matrix(0.173648 0.984808 0.984808 -0.173648 235.461 147.765)"
      fill="white"
    />
    <rect
      x="204.946"
      y="150.653"
      width="0.488377"
      height="3.09306"
      transform="rotate(-90 204.946 150.653)"
      fill="white"
    />
    <rect
      x="235.714"
      y="150.653"
      width="0.488377"
      height="3.09305"
      transform="rotate(-90 235.714 150.653)"
      fill="white"
    />
    <rect
      x="220.158"
      y="133.564"
      width="0.488373"
      height="1.95351"
      transform="rotate(-5 220.158 133.564)"
      fill="white"
    />
    <rect
      width="0.488371"
      height="1.95351"
      transform="matrix(0.996195 -0.0871567 0.0871548 0.996195 222.939 165.351)"
      fill="white"
    />
    <rect
      x="223.109"
      y="133.521"
      width="0.488377"
      height="1.95351"
      transform="rotate(5 223.109 133.521)"
      fill="white"
    />
    <rect
      x="220.329"
      y="165.308"
      width="0.488377"
      height="1.9535"
      transform="rotate(5.00001 220.329 165.308)"
      fill="white"
    />
    <rect
      x="217.259"
      y="134.119"
      width="0.488377"
      height="1.95351"
      transform="rotate(-15 217.259 134.119)"
      fill="white"
    />
    <rect
      width="0.488378"
      height="1.9535"
      transform="matrix(0.965926 -0.258818 0.25882 0.965926 225.517 164.938)"
      fill="white"
    />
    <rect
      width="0.488379"
      height="1.95352"
      transform="matrix(0.965926 0.258819 0.258819 -0.965926 217.259 166.699)"
      fill="white"
    />
    <rect
      width="0.488372"
      height="1.95351"
      transform="matrix(0.965925 0.258822 0.258816 -0.965927 225.517 135.879)"
      fill="white"
    />
    <rect
      x="214.5"
      y="135.168"
      width="0.488378"
      height="1.95351"
      transform="rotate(-25 214.5 135.168)"
      fill="white"
    />
    <rect
      width="0.488378"
      height="1.9535"
      transform="matrix(0.906308 -0.422617 0.42262 0.906307 227.985 164.085)"
      fill="white"
    />
    <rect
      width="0.488378"
      height="1.95351"
      transform="matrix(0.906308 0.422618 0.422618 -0.906308 214.5 165.65)"
      fill="white"
    />
    <rect
      width="0.488378"
      height="1.95351"
      transform="matrix(0.906308 0.422618 0.422618 -0.906308 227.985 136.732)"
      fill="white"
    />
    <rect
      x="211.966"
      y="136.68"
      width="0.488379"
      height="1.95351"
      transform="rotate(-35 211.966 136.68)"
      fill="white"
    />
    <rect
      width="0.488379"
      height="1.95352"
      transform="matrix(0.819151 -0.573578 0.573575 0.819153 230.268 162.817)"
      fill="white"
    />
    <rect
      width="0.488378"
      height="1.95351"
      transform="matrix(0.819152 0.573576 0.573577 -0.819152 211.966 164.137)"
      fill="white"
    />
    <rect
      width="0.488379"
      height="1.95351"
      transform="matrix(0.819153 0.573575 0.573578 -0.819151 230.268 138)"
      fill="white"
    />
    <rect
      x="209.732"
      y="138.609"
      width="0.488377"
      height="1.95351"
      transform="rotate(-45 209.732 138.609)"
      fill="white"
    />
    <rect
      width="0.488375"
      height="1.9535"
      transform="matrix(0.707101 -0.707112 0.707101 0.707112 232.294 161.171)"
      fill="white"
    />
    <rect
      x="207.869"
      y="140.898"
      width="0.488378"
      height="1.95351"
      transform="rotate(-55 207.869 140.898)"
      fill="white"
    />
    <rect
      width="0.488375"
      height="1.9535"
      transform="matrix(0.573578 -0.819151 0.819153 0.573575 234.006 159.199)"
      fill="white"
    />
    <rect
      width="0.488375"
      height="1.9535"
      transform="matrix(0.573576 0.819152 0.819152 -0.573576 207.869 159.919)"
      fill="white"
    />
    <rect
      width="0.488377"
      height="1.95351"
      transform="matrix(0.573576 0.819152 0.819152 -0.573577 234.006 141.618)"
      fill="white"
    />
    <rect
      x="206.43"
      y="143.475"
      width="0.488377"
      height="1.95351"
      transform="rotate(-65 206.43 143.475)"
      fill="white"
    />
    <rect
      x="235.348"
      y="156.959"
      width="0.488377"
      height="1.95351"
      transform="rotate(-65 235.348 156.959)"
      fill="white"
    />
    <rect
      width="0.488377"
      height="1.95351"
      transform="matrix(0.422618 0.906308 0.906308 -0.422618 206.43 157.342)"
      fill="white"
    />
    <rect
      width="0.488377"
      height="1.95351"
      transform="matrix(0.422618 0.906308 0.906308 -0.422619 235.348 143.858)"
      fill="white"
    />
    <rect
      x="205.46"
      y="146.263"
      width="0.488376"
      height="1.95351"
      transform="rotate(-75 205.46 146.263)"
      fill="white"
    />
    <rect
      width="0.488383"
      height="1.95353"
      transform="matrix(0.258818 -0.965926 0.965926 0.25882 236.28 154.521)"
      fill="white"
    />
    <rect
      width="0.488379"
      height="1.95351"
      transform="matrix(0.258819 0.965926 0.965926 -0.258819 205.46 154.555)"
      fill="white"
    />
    <rect
      width="0.488376"
      height="1.95352"
      transform="matrix(0.258821 0.965925 0.965927 -0.258817 236.28 146.296)"
      fill="white"
    />
    <rect
      x="204.99"
      y="149.177"
      width="0.488377"
      height="1.95351"
      transform="rotate(-85 204.99 149.177)"
      fill="white"
    />
    <rect
      x="236.776"
      y="151.958"
      width="0.488378"
      height="1.95351"
      transform="rotate(-85 236.776 151.958)"
      fill="white"
    />
    <rect
      width="0.488377"
      height="1.95351"
      transform="matrix(0.0871558 0.996195 0.996195 -0.0871557 204.99 151.641)"
      fill="white"
    />
    <rect
      width="0.488377"
      height="1.9535"
      transform="matrix(0.0871556 0.996195 0.996195 -0.0871559 236.776 148.86)"
      fill="white"
    />
    <rect
      x="205.033"
      y="152.127"
      width="0.488378"
      height="1.95351"
      transform="rotate(-95 205.033 152.127)"
      fill="white"
    />
    <rect
      width="0.488381"
      height="1.9535"
      transform="matrix(-0.0871548 -0.996195 0.996195 -0.0871567 236.819 149.346)"
      fill="white"
    />
    <rect
      width="0.488373"
      height="1.95351"
      transform="matrix(-0.0871557 0.996195 0.996195 0.0871558 205.033 148.69)"
      fill="white"
    />
    <rect
      width="0.488371"
      height="1.95351"
      transform="matrix(-0.0871567 0.996195 0.996195 0.0871548 236.819 151.471)"
      fill="white"
    />
    <g filter="url(#filter0_d_399_3328)">
      <path
        d="M233.848 138.437L223.028 151.559L220.726 149.257L233.848 138.437Z"
        fill="#FE3C30"
      />
      <path
        d="M209.905 162.38L223.028 151.56L220.726 149.257L209.905 162.38Z"
        fill="white"
      />
    </g>
    <path
      d="M240.624 73.2726L233.206 80.691C235.518 61.7026 225.066 42.6939 206.551 35.0244C199.495 32.1019 191.785 31.0742 184.252 32.0487C176.949 32.9949 169.946 35.7935 164 40.1425L167.653 45.1378C178.327 37.332 191.983 35.6883 204.183 40.7422C221.802 48.0405 230.917 67.3021 226.024 85.2401L214.057 73.2726L209.681 77.6486L227.34 95.3082L245 77.6486L240.624 73.2726Z"
      fill="white"
    />
  </g>
  <defs>
    <filter
      id="filter0_d_399_3328"
      x="208.581"
      y="138.437"
      width="26.5915"
      height="26.5915"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dy="1.32405" />
      <feGaussianBlur stdDeviation="0.662023" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
      />
      <feBlend
        mode="normal"
        in2="BackgroundImageFix"
        result="effect1_dropShadow_399_3328"
      />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="effect1_dropShadow_399_3328"
        result="shape"
      />
    </filter>
    <linearGradient
      id="paint0_linear_399_3328"
      x1="221.877"
      y1="132.232"
      x2="221.877"
      y2="168.585"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#1BC9FA" />
      <stop offset="0.760051" stop-color="#1F70F0" />
    </linearGradient>
    <clipPath id="clip0_399_3328">
      <rect width="261" height="192" rx="16.67" fill="white" />
    </clipPath>
  </defs>
</svg>
