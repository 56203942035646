<svg
  width="80"
  height="80"
  viewBox="0 0 80 80"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M64.8929 28.2337L45.8464 13.433C44.175 12.1339 42.1177 11.4286 40 11.4286C37.8823 11.4286 35.8251 12.1339 34.1536 13.433L15.1036 28.2337C13.9587 29.1231 13.0325 30.2622 12.3956 31.564C11.7587 32.8657 11.4279 34.2956 11.4286 35.7446V61.4351C11.4286 63.3278 12.1811 65.1429 13.5207 66.4813C14.8602 67.8196 16.677 68.5714 18.5714 68.5714H61.4286C63.323 68.5714 65.1398 67.8196 66.4794 66.4813C67.8189 65.1429 68.5714 63.3278 68.5714 61.4351V35.7446C68.5714 32.808 67.2143 30.0356 64.8929 28.2337Z"
    fill="#007848"
  />
  <path
    d="M51.4286 50C45.1143 53.8095 34.88 53.8095 28.5714 50"
    stroke="#F9FAFB"
    stroke-width="7.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
