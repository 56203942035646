<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  {...$$props}
>
  <path
    d="M11.9951 4.00001C10.4984 3.99796 9.03107 4.41583 7.76 5.20613C6.48892 5.99643 5.46504 7.12747 4.80473 8.47067C4.14442 9.81388 3.87417 11.3154 4.02469 12.8045C4.17522 14.2937 4.74047 15.7107 5.65622 16.8946C6.57197 18.0785 7.80148 18.9818 9.20499 19.5018C10.6085 20.0217 12.1297 20.1375 13.5958 19.836C15.0618 19.5344 16.4139 18.8277 17.4983 17.796C18.5827 16.7644 19.3559 15.4492 19.7301 14H17.6491C17.2824 15.0372 16.6376 15.9536 15.7852 16.6491C14.9328 17.3445 13.9056 17.7922 12.8159 17.9432C11.7262 18.0942 10.6159 17.9427 9.6065 17.5053C8.59709 17.0679 7.72738 16.3613 7.09244 15.4629C6.4575 14.5646 6.08179 13.5089 6.00636 12.4114C5.93094 11.3138 6.15872 10.2167 6.66479 9.2399C7.17086 8.2631 7.93575 7.44424 8.87582 6.87283C9.8159 6.30143 10.895 5.99948 11.9951 6.00001C12.782 6.00116 13.5607 6.15917 14.2858 6.4648C15.0109 6.77043 15.6678 7.21754 16.2181 7.78001L13.0001 11H20.0001V4.00001L17.6491 6.35001C16.9083 5.60469 16.0273 5.01333 15.0569 4.61001C14.0866 4.20668 13.046 3.99937 11.9951 4.00001Z"
    fill="currentColor"
  />
</svg>
