<script>
  import { _ } from "svelte-i18n";
  import WhyMe from "../assets/why_me.svelte";
  import HyperpayRadioGroup from "../components/hyperpay_radio_group.svelte";
</script>

<div
  class="flex flex-col items-center justify-center h-screen text-center px-6"
>
  <WhyMe />
  <h2 class="font-bold mt-4">{$_("failure_page.title")}</h2>
  <p class="text-gray-700">{$_("failure_page.subtitle")}</p>
  <HyperpayRadioGroup />
</div>
