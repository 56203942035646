<script>
  import { _ } from "svelte-i18n";
  import CheckMark from "../assets/check_mark.svelte";

  export const steps = [
    $_("steps_nav.salary"),
    $_("steps_nav.obligations"),
    $_("steps_nav.personal"),
  ];
  export let activeStepIndex;

  function isActive(index) {
    return index === activeStepIndex;
  }

  function isDone(index) {
    return index < activeStepIndex;
  }
</script>

<nav class="flex flex-row">
  {#each steps as step, index}
    <div
      class={`text-sm font-semibold h-20 flex items-center justify-center border-b-2 flex-1 ${
        isActive(index)
          ? "text-gray-800 border-b-gray-800"
          : isDone(index)
          ? "text-green-700 border-b-green-700"
          : "text-gray-500"
      }`}
    >
      {#if isDone(index)}
        <CheckMark />
      {/if}
      {step}
    </div>
  {/each}
</nav>
