<svg
  class="me-1"
  width="42"
  height="26"
  viewBox="0 0 42 26"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect x="0.5" y="0.5" width="41" height="25" rx="4.5" stroke="#EEEEEE" />
  <path
    d="M11.6959 9.33487C10.9647 9.33487 10.3748 9.77727 9.99386 9.77727C9.59447 9.77727 9.05991 9.35945 8.42704 9.35945C7.22273 9.35945 6 10.361 6 12.235C6 13.4086 6.45469 14.6436 7.01382 15.4485C7.48694 16.1183 7.91091 16.6713 8.51306 16.6713C9.10906 16.6713 9.37327 16.278 10.1167 16.278C10.8664 16.278 11.0384 16.659 11.6959 16.659C12.3533 16.659 12.7896 16.063 13.2012 15.4731C13.6559 14.7911 13.8525 14.1275 13.8587 14.0968C13.8218 14.0845 12.5684 13.5745 12.5684 12.1367C12.5684 10.8955 13.5515 10.3425 13.6068 10.2995C12.9616 9.35945 11.9724 9.33487 11.6959 9.33487ZM11.3579 8.54224C11.6528 8.17972 11.8618 7.68817 11.8618 7.19048C11.8618 7.12289 11.8556 7.0553 11.8433 7C11.3641 7.01843 10.7742 7.31951 10.424 7.73118C10.1536 8.04455 9.89555 8.54224 9.89555 9.03994C9.89555 9.11367 9.90783 9.19355 9.91398 9.21813C9.9447 9.22427 9.99386 9.23041 10.043 9.23041C10.4793 9.23041 11.0323 8.93548 11.3579 8.54224Z"
    fill="black"
  />
  <path
    d="M16.2739 7.69432V16.5607H17.8592V13.6544H19.7824C21.5765 13.6544 22.83 12.4501 22.83 10.6928C22.83 8.90476 21.6196 7.69432 19.85 7.69432H16.2739ZM17.8592 9.02151H19.4322C20.575 9.02151 21.2202 9.60522 21.2202 10.6928C21.2202 11.7496 20.5566 12.3395 19.426 12.3395H17.8592V9.02151Z"
    fill="black"
  />
  <path
    d="M25.8044 15.4731C25.1285 15.4731 24.6431 15.1352 24.6431 14.5699C24.6431 14.023 25.0425 13.7158 25.8966 13.6605L27.4142 13.5622V14.0968C27.4142 14.8771 26.7261 15.4731 25.8044 15.4731ZM25.3559 16.6713C26.2345 16.6713 26.9718 16.2903 27.3466 15.639H27.4511V16.5607H28.9196V11.9708C28.9196 10.5453 27.9488 9.70353 26.2222 9.70353C24.6247 9.70353 23.5125 10.4593 23.3897 11.6452H24.8274C24.9688 11.1905 25.448 10.9447 26.1485 10.9447C26.9718 10.9447 27.4142 11.3134 27.4142 11.9708V12.5361L25.6815 12.6406C24.0471 12.7389 23.1316 13.4393 23.1316 14.6559C23.1316 15.8848 24.0594 16.6713 25.3559 16.6713Z"
    fill="black"
  />
  <path
    d="M30.616 19C32.1214 19 32.8403 18.447 33.4117 16.7757L35.8387 9.83871H34.2289L32.6621 15.086H32.5576L30.9846 9.83871H29.3134L31.7158 16.5914L31.636 16.8863C31.4393 17.5069 31.0768 17.7527 30.4316 17.7527C30.3272 17.7527 30.0999 17.7465 30.0138 17.7281V18.9754C30.1121 18.9939 30.5238 19 30.616 19Z"
    fill="black"
  />
</svg>
