import { writable, derived, get } from "svelte/store";
import { encodeUserState, decodeUserStateQueryParameter } from "../lib/utils";

const userState = decodeUserStateQueryParameter(window.location.search);

function createAppStore() {
  const { subscribe, set, update } = writable({
    step: 0,
    mobile: null,
    report_id: null,
    // Can be null, 'pending', 'created', 'error'.
    calculationStatus: null,
    // Can be null, 'pending', 'created', 'error'.
    finalCalculationStatus: null,
    ...userState.app,
    // Attributes below are excluded from being saved in the URL.
    // They will always read from html tag attributes and will ignore what's being saved in the URL.
    dir: document.documentElement.dir,
    lang: document.documentElement.lang,
  });

  return {
    subscribe,
    set,
    nextStep: () =>
      update((prevState) => ({ ...prevState, step: prevState.step + 1 })),
    prevStep: () =>
      update((prevState) => ({ ...prevState, step: prevState.step - 1 })),
    firstStep: () => update((prevState) => ({ ...prevState, step: 1 })),
  };
}

export const app = createAppStore();

export const form = writable({
  salary: null,
  employer: null,
  rank: null,
  existing_finances: [],
  existing_credit_card_limits: [],
  age: null,
  redf: null,
  ...userState.form,
});

// Format values for the backend calculator.
export const calculatorParams = derived(form, (formState) => ({
  ...formState,
  existing_credit_card_limits: formState.existing_credit_card_limits
    .filter(({ amount }) => amount > 0)
    .map(({ amount }) => amount),
  existing_finances: formState.existing_finances
    .filter(
      ({ monthly_installment, remaining_amount }) =>
        monthly_installment > 0 && remaining_amount > 0
    )
    .map(({ monthly_installment, remaining_amount }) => ({
      monthly_installment,
      remaining_months: Math.floor(remaining_amount / monthly_installment),
    })),
}));

export const initialCalculation = writable({
  amount: null,
  amount_with_bank_profit: null,
  tenure: null,
  monthly_installment: null,
  ...userState.initialCalculation,
});

export const userStateStore = derived(
  [app, form, initialCalculation],
  ([app, form, initialCalculation]) => ({ app, form, initialCalculation })
);

userStateStore.subscribe((userState) => {
  // Store user state as a JSON stringified base64 string under `user_state` query param.
  // It gets updated on every state changed for certain stores (See userStateStore).
  // This is useful to keep user state on page reloads and can even be constructed from the backend
  // to control the state of the app (i.e payment callback redirection).
  history.pushState(userState, "", `?user_state=${encodeUserState(userState)}`);
});

export const continueInSafariUserState = derived(
  [app, form, initialCalculation],
  ([app, form, initialCalculation]) => ({
    app: { ...app, calculationStatus: null, step: 4 },
    form,
    initialCalculation,
  })
);

function createModalStore() {
  const { subscribe, set, update } = writable(false);

  return {
    subscribe,
    set,
    toggle: () => update((prevState) => !prevState),
    show: () => set(true),
    hide: () => set(false),
  };
}

export const modal = createModalStore();

export const hyperpay = writable({
  checkoutId: {
    mada: null,
    creditcard: null,
    applepay: null,
  },
});

export const finalCalculation = writable({
  amount: 0,
  amount_with_bank_profit: 0,
  tenure: 0,
  profit_rate_percentage: 0,
  financial_firm_name: null,
  bank_profit_percentage_over_amount: 0,
  down_payment: 0,
  admin_fees: 0,
  monthly_installment: {
    0: 0,
  },
});

function createReportStore() {
  const { subscribe, set, update } = writable({
    tab: 1,
  });

  return {
    subscribe,
    set,
    setTab: (newTab) => set({ tab: newTab }),
  };
}

export const reportStore = createReportStore();

export const RANKS = [
  "major_general",
  "brigadier_general",
  "colonel_lieutenant",
  "colonel",
  "major",
  "captain",
  "first_lieutenant",
  "second_lieutenant",
  "master_sergeant",
  "sergeant_first_class",
  "sergeant",
  "vice_sergeant",
  "corporal",
  "private_first_class",
  "private_second_class",
];

if (process.env.NODE_ENV === "development") {
  window.userState = () => get(userStateStore);
  window.finalCalculation = () => get(finalCalculation);
}
