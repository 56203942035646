<script>
  import { locale } from "svelte-i18n";

  const dir = $locale === "ar" ? "rtl" : "ltr";

  /**
   * @type {forward|back|left|right}
   */
  export let direction = "forward";
</script>

{#if direction === "right" || (dir === "ltr" && direction === "forward") || (dir === "rtl" && direction === "back")}
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.02 6L8.60999 7.41L13.19 12L8.60999 16.59L10.02 18L16.02 12L10.02 6Z"
      fill="currentColor"
    />
  </svg>
{:else}
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.98 18L15.39 16.59L10.81 12L15.39 7.41L13.98 6L7.98001 12L13.98 18Z"
      fill="currentColor"
    />
  </svg>
{/if}
