<script>
  import { _ } from "svelte-i18n";
  import { fade } from "svelte/transition";
  import { post } from "axios";
  import { form, app, initialCalculation, calculatorParams } from "../store";
  import StepsNav from "../components/steps_nav.svelte";

  import StepsPaginator from "../components/steps_paginator.svelte";

  import { convertToEnglishNumbers } from "../../lib/utils";
  import { formatValueWithState } from "../../lib/svelte_actions";

  export let nextButtonLabel;

  let isAgeValid = true;
  $: disableNext =
    $form.age === null ||
    $form.redf === null ||
    (Number.isInteger($form.age) && $form.age < 18);

  function validateAge() {
    isAgeValid =
      $form.age === null || (Number.isInteger($form.age) && $form.age >= 18);
  }

  $: disableNext = $form.age === null || $form.redf === null;

  let loading = false;

  function calculate() {
    loading = true;

    return post("/finance_real_estate_calculator", {
      finance_real_estate_calculator: $calculatorParams,
    })
      .then(({ data }) => {
        $initialCalculation = data;
      })
      .finally(() => {
        loading = false;
        app.nextStep();
      });
  }
</script>

<StepsNav activeStepIndex={2} />
<div class="pt-14 px-4 pb-4">
  <label class="flex flex-col justify-center w-full relative">
    <span class="font-bold text-3xl text-center leading-loose">
      {$_("age_page.form.label.age")}
    </span>
    <input
      placeholder="00"
      class="font-bold text-6xl placeholder-gray-900 placeholder-opacity-20 text-center outline-none bg-gray-100 border-2 border-gray-100 focus:bg-white focus:border-green-700 rounded-2xl"
      dir="ltr"
      inputmode="numeric"
      bind:value={$form.age}
      on:blur={validateAge}
      use:formatValueWithState={{
        stateGetter: () => $form.age,
        stateSetter: (val) => ($form.age = val),
        formatter: (val) => val,
        parser: (val) => parseInt(convertToEnglishNumbers(val) || 0),
      }}
    />
    <span class="text-center font-light">{$_("common.year")}</span>
    {#if !isAgeValid}
      <div
        out:fade={{ duration: 100 }}
        class="shake flex flex-row justify-center items-end absolute bottom-0 left-0 right-0"
      >
        <p class="bg-red-200 text-red-900 px-4 py-2 -mb-5 rounded-xl">
          {$_("age_page.form.error.age_below_minimum")}
        </p>
      </div>
    {/if}
  </label>

  <p class="mt-6 text-center font-light">
    {$_("age_page.form.label.redfSupport")}
  </p>
  <fieldset class="p-2 flex flex-wrap justify-center">
    <label class="me-2 mt-2">
      <input
        class="hidden peer"
        type="radio"
        bind:group={$form.redf}
        value={false}
      />
      <div
        class="bg-gray-200 py-2 px-4 rounded-lg peer-checked:bg-gray-800 peer-checked:text-white"
      >
        {$_("age_page.form.label.no")}
      </div>
    </label>

    <label class="me-2 mt-2">
      <input
        class="hidden peer"
        type="radio"
        bind:group={$form.redf}
        value={true}
      />
      <div
        class="bg-gray-200 py-2 px-4 rounded-lg peer-checked:bg-gray-800 peer-checked:text-white"
      >
        {$_("age_page.form.label.yes")}
      </div>
    </label>
  </fieldset>
</div>

<StepsPaginator onNext={calculate} {disableNext} {nextButtonLabel} {loading} />
