<svg
  class="absolute top-4 z-[-1]"
  width="277"
  height="183"
  viewBox="0 0 277 183"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M11.4213 178.309C9.27247 179.033 6.93414 177.898 6.24724 175.737C0.0714836 156.308 -1.55782 135.749 1.50349 115.589C4.7693 94.0817 13.2774 73.6594 26.3092 56.0465C39.3411 38.4337 56.5139 24.1477 76.3773 14.3951C96.2408 4.64246 118.211 -0.290373 140.434 0.013208C162.656 0.316789 184.477 5.84787 204.055 16.1393C223.632 26.4307 240.391 41.1803 252.915 59.1422C265.439 77.1042 273.361 97.751 276.011 119.339C278.496 139.577 276.28 160.086 269.551 179.34C268.803 181.478 266.437 182.547 264.311 181.765V181.765C262.141 180.967 261.051 178.55 261.807 176.365C268.04 158.366 270.082 139.212 267.761 120.309C265.269 100.016 257.822 80.6079 246.05 63.7237C234.277 46.8395 218.524 32.9749 200.121 23.3009C181.719 13.627 161.207 8.42778 140.318 8.14241C119.429 7.85705 98.7763 12.4939 80.1047 21.6614C61.433 30.8289 45.2906 44.2577 33.0407 60.8137C20.7908 77.3698 12.7931 96.5668 9.72328 116.783C6.86353 135.616 8.35832 154.819 14.0747 172.982C14.7685 175.186 13.6116 177.571 11.4213 178.309V178.309Z"
    fill="#F6F6F9"
  />
</svg>
