export function formatValueWithState(node, options) {
  const { parser, formatter, stateGetter, stateSetter } = options;

  node.addEventListener("input", (event) => {
    stateSetter(parser(event.target.value));
    // TODO: Fix caret position after formatting
    node.value = formatter(stateGetter());
  });

  // Run on hydration
  node.value = formatter(stateGetter());
}
