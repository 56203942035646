<script>
  import { _ } from "svelte-i18n";
  import { onMount } from "svelte";
  import { post } from "axios";
  import { hyperpay, app } from "../store";
  import Spinner from "./spinner.svelte";

  export let entityType = null;
  let paymentLoading = true;
  $: checkoutId = $hyperpay.checkoutId[entityType];

  onMount(() => {
    if (
      (entityType === "mada" && $hyperpay.checkoutId.mada === null) ||
      (entityType === "creditcard" &&
        $hyperpay.checkoutId.creditcard === null) ||
      (entityType === "applepay" && $hyperpay.checkoutId.applepay === null)
    ) {
      post(`/create_hyperpay_checkout?entity_type=${entityType}`).then(
        ({ data }) => {
          $hyperpay.checkoutId[entityType] = data.checkout_id;
        }
      );
    }
  });

  window.wpwlOptions = {
    style: "plain",
    locale: $app.lang,
    showLabels: false,
    labels: {
      // used for the labels and placeholder.
      cvv: $_("summary_page.hyperpay.cvv"),
    },
    spinner: {
      className: "hidden",
    },
    onReady: function () {
      paymentLoading = false;
    },
    applePay: {
      displayName: document.title,
      total: { label: document.title },
      supportedNetworks: ["masterCard", "visa", "mada"],
      supportedCountries: ["SA"],
    },
  };
</script>

<div
  class="p-4 pb-3"
  style="--wpwl-control-align:{$app.dir === 'rtl' ? 'right' : 'left'}"
>
  {#if checkoutId}
    <script
      src="{window.hyperpay_base_url}/v1/paymentWidgets.js?checkoutId={checkoutId}"></script>
    <form
      action={window.payment_callback_path}
      class="paymentWidgets"
      data-brands={entityType === "applepay"
        ? "APPLEPAY"
        : "MADA VISA MASTER AMEX"}
    />
  {/if}
  {#if paymentLoading}
    <Spinner color="gray" />
  {/if}
</div>

<style>
  :global(.wpwl-group-brand) {
    display: none;
  }

  :global(.wpwl-apple-pay-button) {
    -webkit-appearance: -apple-pay-button !important;
    -apple-pay-button-type: plain;
    -apple-pay-button-style: black;
    border-radius: 12px;
    height: 56px;
    width: 100%;
  }

  :global(.wpwl-control) {
    border: none;
    background: rgb(240, 240, 240);
    border-radius: 16px;
    width: 100%;
    height: 48px;
    padding: 0 16px;
    font-size: 16px;
    direction: ltr;
    text-align: var(--wpwl-control-align);
  }

  :global(.wpwl-button-pay) {
    float: none;
    width: 100%;
    height: 56px;
    border-radius: 16px;
    background-color: #007848;
  }

  :global(.wpwl-wrapper) {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: none;
  }

  :global(.wpwl-hint) {
    padding: 0 16px;
  }

  :global(.wpwl-form-virtualAccount-APPLEPAY, .wpwl-form-card) {
    margin-bottom: 0;
  }
</style>
