<svg
  width="261"
  height="192"
  viewBox="0 0 261 192"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_413_27044)">
    <rect width="261.468" height="192" fill="#063126" />
    <rect
      x="7.9458"
      y="118.458"
      width="246.576"
      height="64.5961"
      rx="16"
      fill="#F5F5F5"
      fill-opacity="0.3"
    />
    <rect
      x="19.7539"
      y="129.571"
      width="41.6749"
      height="41.6749"
      rx="9.02956"
      fill="white"
    />
    <rect
      x="19.4066"
      y="129.224"
      width="42.3695"
      height="42.3695"
      rx="9.37685"
      stroke="black"
      stroke-opacity="0.07"
      stroke-width="0.694581"
    />
    <circle
      cx="40.5912"
      cy="150.409"
      r="18.2328"
      fill="url(#paint0_linear_413_27044)"
    />
    <rect
      x="40.3472"
      y="133.478"
      width="0.488377"
      height="3.09306"
      fill="white"
    />
    <rect
      x="40.3472"
      y="164.246"
      width="0.488377"
      height="3.09306"
      fill="white"
    />
    <rect
      x="37.4106"
      y="133.778"
      width="0.488378"
      height="3.09306"
      transform="rotate(-10 37.4106 133.778)"
      fill="white"
    />
    <rect
      width="0.488381"
      height="3.09305"
      transform="matrix(0.984808 -0.173646 0.17365 0.984807 42.7534 164.078)"
      fill="white"
    />
    <rect
      x="34.5713"
      y="134.583"
      width="0.488379"
      height="3.09306"
      transform="rotate(-20 34.5713 134.583)"
      fill="white"
    />
    <rect
      x="45.0947"
      y="163.495"
      width="0.488377"
      height="3.09305"
      transform="rotate(-20 45.0947 163.495)"
      fill="white"
    />
    <rect
      width="0.488379"
      height="3.09306"
      transform="matrix(0.939693 0.34202 0.34202 -0.939693 34.5713 166.235)"
      fill="white"
    />
    <rect
      width="0.488377"
      height="3.09306"
      transform="matrix(0.939692 0.342021 0.34202 -0.939693 45.0947 137.323)"
      fill="white"
    />
    <rect
      width="0.488377"
      height="3.09304"
      transform="matrix(0.984808 0.173648 0.173648 -0.984808 37.4106 167.04)"
      fill="white"
    />
    <rect
      width="0.488381"
      height="3.09306"
      transform="matrix(0.984808 0.173647 0.17365 -0.984807 42.7534 136.739)"
      fill="white"
    />
    <rect
      x="31.9146"
      y="135.869"
      width="0.488377"
      height="3.09306"
      transform="rotate(-30 31.9146 135.869)"
      fill="white"
    />
    <rect
      width="0.488376"
      height="3.09306"
      transform="matrix(0.866023 -0.500004 0.499997 0.866027 47.2983 162.514)"
      fill="white"
    />
    <rect
      width="0.488377"
      height="3.09305"
      transform="matrix(0.866025 0.5 0.5 -0.866025 31.9146 164.949)"
      fill="white"
    />
    <rect
      width="0.488376"
      height="3.09305"
      transform="matrix(0.866025 0.500001 0.499999 -0.866026 47.2983 138.303)"
      fill="white"
    />
    <rect
      x="29.5215"
      y="137.596"
      width="0.488377"
      height="3.09305"
      transform="rotate(-40 29.5215 137.596)"
      fill="white"
    />
    <rect
      x="49.2988"
      y="161.166"
      width="0.488378"
      height="3.09306"
      transform="rotate(-40 49.2988 161.166)"
      fill="white"
    />
    <rect
      width="0.488377"
      height="3.09305"
      transform="matrix(0.766044 0.642788 0.642788 -0.766045 29.5215 163.221)"
      fill="white"
    />
    <rect
      width="0.488378"
      height="3.09306"
      transform="matrix(0.766045 0.642787 0.642788 -0.766044 49.2988 139.651)"
      fill="white"
    />
    <rect
      x="27.4648"
      y="139.713"
      width="0.488378"
      height="3.09306"
      transform="rotate(-50 27.4648 139.713)"
      fill="white"
    />
    <rect
      width="0.488377"
      height="3.09306"
      transform="matrix(0.642787 -0.766045 0.766044 0.642788 51.0342 159.49)"
      fill="white"
    />
    <rect
      width="0.488376"
      height="3.09305"
      transform="matrix(0.642787 0.766045 0.766044 -0.642788 27.4648 161.104)"
      fill="white"
    />
    <rect
      width="0.488377"
      height="3.09306"
      transform="matrix(0.642787 0.766045 0.766044 -0.642788 51.0342 141.327)"
      fill="white"
    />
    <rect
      x="25.8071"
      y="142.155"
      width="0.488377"
      height="3.09306"
      transform="rotate(-60 25.8071 142.155)"
      fill="white"
    />
    <rect
      width="0.488378"
      height="3.09307"
      transform="matrix(0.500002 -0.866024 0.866027 0.499998 52.4526 157.539)"
      fill="white"
    />
    <rect
      width="0.488379"
      height="3.09306"
      transform="matrix(0.5 0.866025 0.866025 -0.5 25.8071 158.663)"
      fill="white"
    />
    <rect
      width="0.488378"
      height="3.09307"
      transform="matrix(0.500001 0.866025 0.866026 -0.499999 52.4526 143.279)"
      fill="white"
    />
    <rect
      x="24.5986"
      y="144.848"
      width="0.488379"
      height="3.09306"
      transform="rotate(-70 24.5986 144.848)"
      fill="white"
    />
    <rect
      x="53.5107"
      y="155.371"
      width="0.488376"
      height="3.09305"
      transform="rotate(-70 53.5107 155.371)"
      fill="white"
    />
    <rect
      width="0.488377"
      height="3.09306"
      transform="matrix(0.34202 0.939693 0.939693 -0.34202 24.5986 155.97)"
      fill="white"
    />
    <rect
      width="0.488374"
      height="3.09305"
      transform="matrix(0.342022 0.939692 0.939693 -0.342019 53.5107 145.447)"
      fill="white"
    />
    <rect
      x="23.8755"
      y="147.709"
      width="0.488379"
      height="3.09306"
      transform="rotate(-80 23.8755 147.709)"
      fill="white"
    />
    <rect
      width="0.488382"
      height="3.09306"
      transform="matrix(0.173647 -0.984808 0.984807 0.173649 54.1758 153.052)"
      fill="white"
    />
    <rect
      width="0.488375"
      height="3.09306"
      transform="matrix(0.173648 0.984808 0.984808 -0.173648 23.8755 153.108)"
      fill="white"
    />
    <rect
      width="0.488377"
      height="3.09305"
      transform="matrix(0.173648 0.984808 0.984808 -0.173648 54.1758 147.765)"
      fill="white"
    />
    <rect
      x="23.6606"
      y="150.653"
      width="0.488377"
      height="3.09306"
      transform="rotate(-90 23.6606 150.653)"
      fill="white"
    />
    <rect
      x="54.4282"
      y="150.653"
      width="0.488377"
      height="3.09305"
      transform="rotate(-90 54.4282 150.653)"
      fill="white"
    />
    <rect
      x="38.8726"
      y="133.564"
      width="0.488373"
      height="1.95351"
      transform="rotate(-5 38.8726 133.564)"
      fill="white"
    />
    <rect
      width="0.488371"
      height="1.95351"
      transform="matrix(0.996195 -0.0871567 0.0871548 0.996195 41.6538 165.351)"
      fill="white"
    />
    <rect
      x="41.8237"
      y="133.521"
      width="0.488377"
      height="1.95351"
      transform="rotate(5 41.8237 133.521)"
      fill="white"
    />
    <rect
      x="39.043"
      y="165.308"
      width="0.488377"
      height="1.9535"
      transform="rotate(5.00001 39.043 165.308)"
      fill="white"
    />
    <rect
      x="35.9731"
      y="134.119"
      width="0.488377"
      height="1.95351"
      transform="rotate(-15 35.9731 134.119)"
      fill="white"
    />
    <rect
      width="0.488378"
      height="1.9535"
      transform="matrix(0.965926 -0.258818 0.25882 0.965926 44.2314 164.938)"
      fill="white"
    />
    <rect
      width="0.488379"
      height="1.95352"
      transform="matrix(0.965926 0.258819 0.258819 -0.965926 35.9731 166.699)"
      fill="white"
    />
    <rect
      width="0.488372"
      height="1.95351"
      transform="matrix(0.965925 0.258822 0.258816 -0.965927 44.2314 135.879)"
      fill="white"
    />
    <rect
      x="33.2148"
      y="135.168"
      width="0.488378"
      height="1.95351"
      transform="rotate(-25 33.2148 135.168)"
      fill="white"
    />
    <rect
      width="0.488378"
      height="1.9535"
      transform="matrix(0.906308 -0.422617 0.42262 0.906307 46.6997 164.085)"
      fill="white"
    />
    <rect
      width="0.488378"
      height="1.95351"
      transform="matrix(0.906308 0.422618 0.422618 -0.906308 33.2148 165.65)"
      fill="white"
    />
    <rect
      width="0.488378"
      height="1.95351"
      transform="matrix(0.906308 0.422618 0.422618 -0.906308 46.6992 136.732)"
      fill="white"
    />
    <rect
      x="30.6807"
      y="136.68"
      width="0.488379"
      height="1.95351"
      transform="rotate(-35 30.6807 136.68)"
      fill="white"
    />
    <rect
      width="0.488379"
      height="1.95352"
      transform="matrix(0.819151 -0.573578 0.573575 0.819153 48.9819 162.817)"
      fill="white"
    />
    <rect
      width="0.488378"
      height="1.95351"
      transform="matrix(0.819152 0.573576 0.573577 -0.819152 30.6807 164.137)"
      fill="white"
    />
    <rect
      width="0.488379"
      height="1.95351"
      transform="matrix(0.819153 0.573575 0.573578 -0.819151 48.9819 138)"
      fill="white"
    />
    <rect
      x="28.4468"
      y="138.609"
      width="0.488377"
      height="1.95351"
      transform="rotate(-45 28.4468 138.609)"
      fill="white"
    />
    <rect
      width="0.488375"
      height="1.9535"
      transform="matrix(0.707101 -0.707112 0.707101 0.707112 51.0088 161.171)"
      fill="white"
    />
    <rect
      x="26.583"
      y="140.898"
      width="0.488378"
      height="1.95351"
      transform="rotate(-55 26.583 140.898)"
      fill="white"
    />
    <rect
      width="0.488375"
      height="1.9535"
      transform="matrix(0.573578 -0.819151 0.819153 0.573575 52.7202 159.199)"
      fill="white"
    />
    <rect
      width="0.488375"
      height="1.9535"
      transform="matrix(0.573576 0.819152 0.819152 -0.573576 26.583 159.919)"
      fill="white"
    />
    <rect
      width="0.488377"
      height="1.95351"
      transform="matrix(0.573576 0.819152 0.819152 -0.573577 52.7202 141.618)"
      fill="white"
    />
    <rect
      x="25.144"
      y="143.475"
      width="0.488377"
      height="1.95351"
      transform="rotate(-65 25.144 143.475)"
      fill="white"
    />
    <rect
      x="54.062"
      y="156.959"
      width="0.488377"
      height="1.95351"
      transform="rotate(-65 54.062 156.959)"
      fill="white"
    />
    <rect
      width="0.488377"
      height="1.95351"
      transform="matrix(0.422618 0.906308 0.906308 -0.422618 25.144 157.342)"
      fill="white"
    />
    <rect
      width="0.488377"
      height="1.95351"
      transform="matrix(0.422618 0.906308 0.906308 -0.422619 54.062 143.858)"
      fill="white"
    />
    <rect
      x="24.1748"
      y="146.263"
      width="0.488376"
      height="1.95351"
      transform="rotate(-75 24.1748 146.263)"
      fill="white"
    />
    <rect
      width="0.488383"
      height="1.95353"
      transform="matrix(0.258818 -0.965926 0.965926 0.25882 54.9946 154.521)"
      fill="white"
    />
    <rect
      width="0.488379"
      height="1.95351"
      transform="matrix(0.258819 0.965926 0.965926 -0.258819 24.1748 154.555)"
      fill="white"
    />
    <rect
      width="0.488376"
      height="1.95352"
      transform="matrix(0.258821 0.965925 0.965927 -0.258817 54.9946 146.296)"
      fill="white"
    />
    <rect
      x="23.7041"
      y="149.177"
      width="0.488377"
      height="1.95351"
      transform="rotate(-85 23.7041 149.177)"
      fill="white"
    />
    <rect
      x="55.4902"
      y="151.958"
      width="0.488378"
      height="1.95351"
      transform="rotate(-85 55.4902 151.958)"
      fill="white"
    />
    <rect
      width="0.488377"
      height="1.95351"
      transform="matrix(0.0871558 0.996195 0.996195 -0.0871557 23.7041 151.641)"
      fill="white"
    />
    <rect
      width="0.488377"
      height="1.9535"
      transform="matrix(0.0871556 0.996195 0.996195 -0.0871559 55.4902 148.86)"
      fill="white"
    />
    <rect
      x="23.7471"
      y="152.127"
      width="0.488378"
      height="1.95351"
      transform="rotate(-95 23.7471 152.127)"
      fill="white"
    />
    <rect
      width="0.488381"
      height="1.9535"
      transform="matrix(-0.0871548 -0.996195 0.996195 -0.0871567 55.5332 149.346)"
      fill="white"
    />
    <rect
      width="0.488373"
      height="1.95351"
      transform="matrix(-0.0871557 0.996195 0.996195 0.0871558 23.7471 148.69)"
      fill="white"
    />
    <rect
      width="0.488371"
      height="1.95351"
      transform="matrix(-0.0871567 0.996195 0.996195 0.0871548 55.5332 151.471)"
      fill="white"
    />
    <g filter="url(#filter0_d_413_27044)">
      <path
        d="M52.5627 138.437L41.7422 151.559L39.44 149.257L52.5627 138.437Z"
        fill="#FE3C30"
      />
      <path
        d="M28.6194 162.38L41.7421 151.56L39.4399 149.257L28.6194 162.38Z"
        fill="white"
      />
    </g>
    <rect
      x="80.3452"
      y="129.571"
      width="41.6749"
      height="41.6749"
      rx="11.1133"
      fill="#F5F5F5"
      fill-opacity="0.3"
    />
    <rect
      x="140.773"
      y="129.571"
      width="41.6749"
      height="41.6749"
      rx="11.1133"
      fill="#F5F5F5"
      fill-opacity="0.3"
    />
    <rect
      x="201.202"
      y="129.571"
      width="41.6749"
      height="41.6749"
      rx="11.1133"
      fill="#F5F5F5"
      fill-opacity="0.3"
    />
    <path
      d="M24.376 73.2726L31.7944 80.691C29.4821 61.7026 39.934 42.6939 58.4493 35.0244C65.5048 32.1019 73.2151 31.0742 80.7479 32.0487C88.0506 32.9949 95.0537 35.7935 101 40.1425L97.3468 45.1378C86.6734 37.332 73.0174 35.6883 60.8175 40.7422C43.1981 48.0405 34.0832 67.3021 38.976 85.2401L50.9434 73.2726L55.3195 77.6486L37.6598 95.3082L20.0002 77.6486L24.376 73.2726Z"
      fill="white"
    />
  </g>
  <defs>
    <filter
      id="filter0_d_413_27044"
      x="27.2951"
      y="138.437"
      width="26.5915"
      height="26.5915"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dy="1.32405" />
      <feGaussianBlur stdDeviation="0.662023" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
      />
      <feBlend
        mode="normal"
        in2="BackgroundImageFix"
        result="effect1_dropShadow_413_27044"
      />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="effect1_dropShadow_413_27044"
        result="shape"
      />
    </filter>
    <linearGradient
      id="paint0_linear_413_27044"
      x1="40.5912"
      y1="132.232"
      x2="40.5912"
      y2="168.585"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#1BC9FA" />
      <stop offset="0.760051" stop-color="#1F70F0" />
    </linearGradient>
    <clipPath id="clip0_413_27044">
      <rect width="261" height="192" rx="16.67" fill="white" />
    </clipPath>
  </defs>
</svg>
