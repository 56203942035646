<script>
  import StepsNav from "../components/steps_nav.svelte";
  import StepsPaginator from "../components/steps_paginator.svelte";
  import { fade } from "svelte/transition";
  import {
    prettyNumbers,
    convertToEnglishNumbers,
    integerFromFormattedStringOrNull,
  } from "../../lib/utils";
  import { formatValueWithState } from "../../lib/svelte_actions";

  import { _ } from "svelte-i18n";
  import { app, form, RANKS } from "../store";

  export let nextButtonLabel;

  let isSalaryValid = true;
  $: disableNext =
    $form.salary === null ||
    (Number.isInteger($form.salary) && $form.salary < 3000) ||
    $form.employer === null ||
    ($form.employer === "military" && $form.rank === null);

  function validateSalary() {
    isSalaryValid =
      $form.salary === null ||
      (Number.isInteger($form.salary) && $form.salary >= 3000);
  }
</script>

<StepsNav activeStepIndex={0} />
<div class="pt-14 px-4 pb-4">
  <label class="flex flex-col justify-center w-full relative">
    <span class="font-bold text-3xl text-center leading-loose"
      >{$_("salary_page.form.label.salary")}</span
    >
    <input
      type="text"
      placeholder="0"
      class="font-bold text-6xl placeholder-gray-900 placeholder-opacity-20 text-center outline-none bg-gray-100 border-2 border-gray-100 focus:bg-white focus:border-green-700 rounded-2xl"
      dir="ltr"
      use:formatValueWithState={{
        stateGetter: () => $form.salary,
        stateSetter: (val) => ($form.salary = val),
        formatter: prettyNumbers,
        parser: (val) =>
          integerFromFormattedStringOrNull(convertToEnglishNumbers(val)),
      }}
      on:blur={validateSalary}
    />
    <span class="text-center font-light">{$_("common.sar")}</span>
    {#if !isSalaryValid}
      <div
        out:fade={{ duration: 100 }}
        class="shake flex flex-row justify-center items-end absolute bottom-0 left-0 right-0"
      >
        <p class="bg-red-200 text-red-900 px-4 py-2 -mb-5 rounded-xl">
          {$_("salary_page.form.error.salary_below_minimum")}
        </p>
      </div>
    {/if}
  </label>

  <fieldset class="p-6 flex flex-wrap justify-center">
    <label class="me-2 mt-2">
      <input
        class="hidden peer"
        type="radio"
        bind:group={$form.employer}
        value="government"
      />
      <div
        class="bg-gray-200 py-2 px-4 rounded-lg peer-checked:bg-gray-800 peer-checked:text-white"
      >
        {$_("common.employer.government")}
      </div>
    </label>

    <label class="me-2 mt-2">
      <input
        class="hidden peer"
        type="radio"
        bind:group={$form.employer}
        value="private"
      />
      <div
        class="bg-gray-200 py-2 px-4 rounded-lg peer-checked:bg-gray-800 peer-checked:text-white"
      >
        {$_("common.employer.private")}
      </div>
    </label>

    <label class="me-2 mt-2">
      <input
        class="hidden peer"
        type="radio"
        bind:group={$form.employer}
        value="military"
      />
      <div
        class="bg-gray-200 py-2 px-4 rounded-lg peer-checked:bg-gray-800 peer-checked:text-white"
      >
        {$_("common.employer.military")}
      </div>
    </label>

    <label class="me-2 mt-2">
      <input
        class="hidden peer"
        type="radio"
        bind:group={$form.employer}
        value="retired"
      />
      <div
        class="bg-gray-200 py-2 px-4 rounded-lg peer-checked:bg-gray-800 peer-checked:text-white"
      >
        {$_("common.employer.retired")}
      </div>
    </label>
  </fieldset>
  {#if $form.employer === "military"}
    <div class="flex justify-center">
      <select
        bind:value={$form.rank}
        class="
          bg-gray-200
          w-52
          py-2
          px-5
          rounded-lg
          appearance-none
          icon-arrow-down
          bg-no-repeat
          {$app.dir === 'rtl' ? 'bg-left' : 'bg-right'}
          focus:outline-none
        "
      >
        <option value={null} selected disabled>
          {$_("common.employer.ranks.select_rank")}
        </option>
        {#each RANKS as rank}
          <option value={rank}>{$_(`common.employer.ranks.${rank}`)}</option>
        {/each}
      </select>
    </div>
  {/if}
</div>

<StepsPaginator {disableNext} {nextButtonLabel} />
