<svg
  class="flex-shrink-0"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M15.9899 10.9652C16.0947 10.8533 16.1764 10.7219 16.2304 10.5785C16.2844 10.4351 16.3097 10.2824 16.3047 10.1292C16.2997 9.97602 16.2646 9.8253 16.2014 9.68568C16.1381 9.54606 16.048 9.42025 15.9362 9.31546C15.8243 9.21066 15.693 9.12891 15.5495 9.07489C15.4061 9.02087 15.2534 8.99563 15.1002 9.00062C14.947 9.0056 14.7963 9.04071 14.6567 9.10394C14.5171 9.16717 14.3913 9.25728 14.2865 9.36912L10.6895 13.2076L8.91256 11.6291C8.67975 11.4355 8.38083 11.3401 8.07894 11.3628C7.77705 11.3856 7.49584 11.5248 7.2947 11.7511C7.09357 11.9774 6.98827 12.273 7.00104 12.5755C7.01381 12.8779 7.14367 13.1636 7.36316 13.3721L9.98827 15.7056C10.2154 15.9073 10.5123 16.0125 10.8158 15.9988C11.1193 15.9851 11.4055 15.8535 11.6135 15.6321L15.9899 10.9652Z"
    fill="#007848"
  />
</svg>
