<script>
  import "./locale";
  import { _ } from "svelte-i18n";

  import { app } from "./store";
  import LandingPage from "./pages/landing_page.svelte";
  import SalaryPage from "./pages/salary_page.svelte";
  import ObligationsPage from "./pages/obligations_page.svelte";
  import AgePage from "./pages/age_page.svelte";
  import SummaryPage from "./pages/summary_page.svelte";
  import PaymentFailedPage from "./pages/payment_failed_page.svelte";
  import PaymentSucceedPage from "./pages/payment_succeed_page.svelte";
  import ReportPage from "./pages/report_page.svelte";

  let pages = [
    {
      //step: 0
      component: LandingPage,
      nextButtonLabel: $_("landing_page.nextButtonLabel"),
    },
    {
      //step: 1
      component: SalaryPage,
      nextButtonLabel: $_("salary_page.nextButtonLabel"),
    },
    {
      //step: 2
      component: ObligationsPage,
      nextButtonLabel: $_("obligations_page.nextButtonLabel"),
    },
    {
      //step: 3
      component: AgePage,
      nextButtonLabel: $_("age_page.nextButtonLabel"),
    },
    {
      //step: 4
      component: SummaryPage,
      nextButtonLabel: null,
    },
    {
      //step: 5
      component: PaymentFailedPage,
      nextButtonLabel: null,
    },
    {
      //step: 6
      component: PaymentSucceedPage,
      nextButtonLabel: null,
    },
    {
      //step: 7
      component: ReportPage,
      nextButtonLabel: null,
    },
  ];
</script>

<svelte:component
  this={pages[$app.step].component}
  nextButtonLabel={pages[$app.step].nextButtonLabel}
/>
